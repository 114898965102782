@use "../../../_global/_styles/mixins" as theme;

.sidenav-header {
    background-color: $color-primary;
    color: $color-primary-contrast;

    @include theme.dark-theme {
        background-color: $background-hover;
        color: $foreground-color;
    }
}
