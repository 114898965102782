@use "@angular/material" as mat;
@import "../../_global/_styles/mixins";

.header {
    background-color: $color-primary;
    color: $color-primary-contrast;

    @include dark-theme($is-dark-theme) {
        background-color: $background-hover;
        color: $foreground-color;
    }
}

.header-seperator {
    background-color: $color-accent;
}

toitsu-ng-material-header-user {
    .dropdown {
        color: $text-color;
        background: $background-card;
        @include mat.elevation(3);

        .list-item {
            &:hover {
                background: $background-hover;
            }
        }

        .list-item-icon {
            color: $icon-color;
        }
    }
}
