/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
@media only screen and (min-width: 991px) {
  body ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }
  body ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  body ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
    border-radius: 10px;
  }
  body ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
    border-radius: 10px;
  }
  body.dark ::-webkit-scrollbar:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
  body.dark ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.37);
  }
  body.dark ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.54);
  }
}

:root {
  --font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-weight-medium: 500;
  --font-caption: 400 0.75rem / 1.7 var(--font-family);
  --font-body-1: 400 1.1875rem / 1.5 var(--font-family);
  --font-body-2: 500 1.1875rem / 1.5 var(--font-family);
  --font-subheading-1: bold 1.1875rem / 1.5 var(--font-family);
  --font-subheading-2: 500 1.5rem / 1.5 var(--font-family);
  --font-headline: bold 2.5rem / 65px var(--font-family);
  --font-title: bold 1.7rem / 1.2 var(--font-family);
  --font-display-1: 400 34px / 40px var(--font-family);
  --font-display-2: 400 45px / 48px var(--font-family);
  --font-display-3: 400 56px / 56px var(--font-family);
  --font-display-4: 300 112px / 112px var(--font-family);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font-family);
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: bold 2.5rem / 65px var(--font-family);
  letter-spacing: -0.035em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: bold 1.7rem / 1.2 var(--font-family);
  letter-spacing: -0.035em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 500 1.5rem / 1.5 var(--font-family);
  letter-spacing: -0.035em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: bold 1.1875rem / 1.5 var(--font-family);
  letter-spacing: -0.035em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(1.1875rem * 0.83) / 1.5 var(--font-family);
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(1.1875rem * 0.67) / 1.5 var(--font-family);
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 1.1875rem / 1.5 var(--font-family);
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 1.1875rem / 1.5 var(--font-family);
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 0.75rem / 1.7 var(--font-family);
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px var(--font-family);
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px var(--font-family);
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px var(--font-family);
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 1.1875rem / 1.5 var(--font-family);
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: var(--font-family);
  font-size: 1.1875rem;
  font-weight: 700;
}

.mat-button-toggle {
  font-family: var(--font-family);
}

.mat-card {
  font-family: var(--font-family);
}

.mat-card-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.mat-card-header .mat-card-title {
  font-size: 1.7rem;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 1.1875rem;
}

.mat-checkbox {
  font-family: var(--font-family);
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 1.5;
}

.mat-chip {
  font-size: 1.1875rem;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: var(--font-family);
}

.mat-header-cell {
  font-size: 0.75rem;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 1.1875rem;
}

.mat-calendar {
  font-family: var(--font-family);
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 1.1875rem;
  font-weight: 700;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: bold 1.7rem / 1.2 var(--font-family);
  letter-spacing: -0.035em;
}

.mat-expansion-panel-header {
  font-family: var(--font-family);
  font-size: 1.1875rem;
  font-weight: bold;
}

.mat-expansion-panel-content {
  font: 400 1.1875rem / 1.5 var(--font-family);
  letter-spacing: normal;
}

.mat-form-field {
  font: 400 1.1875rem / 1.125 var(--font-family);
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 1.1875rem;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 0.75rem;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: var(--font-family);
  font-size: 1.1875rem;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: var(--font-family);
  font-size: 0.75rem;
}

.mat-radio-button {
  font-family: var(--font-family);
}

.mat-select {
  font-family: var(--font-family);
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: var(--font-family);
}

.mat-slider-thumb-label-text {
  font-family: var(--font-family);
  font-size: 0.75rem;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: var(--font-family);
}

.mat-step-label {
  font-size: 1.1875rem;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 1.1875rem;
}

.mat-step-label-selected {
  font-size: 1.1875rem;
  font-weight: 500;
}

.mat-tab-group {
  font-family: var(--font-family);
}

.mat-tab-label, .mat-tab-link {
  font-family: var(--font-family);
  font-size: 1.1875rem;
  font-weight: 700;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: bold 1.7rem / 1.2 var(--font-family);
  letter-spacing: -0.035em;
  margin: 0;
}

.mat-tooltip {
  font-family: var(--font-family);
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: var(--font-family);
}

.mat-list-option {
  font-family: var(--font-family);
}

.mat-list-base .mat-list-item {
  font-size: 1.5rem;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 1.1875rem;
}
.mat-list-base .mat-list-option {
  font-size: 1.5rem;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 1.1875rem;
}
.mat-list-base .mat-subheader {
  font-family: var(--font-family);
  font-size: 1.1875rem;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 0.75rem;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 0.75rem;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 0.75rem;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 0.75rem;
}
.mat-list-base[dense] .mat-subheader {
  font-family: var(--font-family);
  font-size: 0.75rem;
  font-weight: 500;
}

.mat-option {
  font-family: var(--font-family);
  font-size: 1.5rem;
}

.mat-optgroup-label {
  font: 500 1.1875rem / 1.5 var(--font-family);
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: var(--font-family);
  font-size: 1.1875rem;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
}

.mat-tree {
  font-family: var(--font-family);
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 1.1875rem;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.toitsu-ng-default {
  /**
  **  Custom snackbar styles
   */
}
.toitsu-ng-default .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.toitsu-ng-default .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-option:hover:not(.mat-option-disabled), .toitsu-ng-default .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}
.toitsu-ng-default .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.toitsu-ng-default .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.toitsu-ng-default .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.toitsu-ng-default .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.toitsu-ng-default .mat-primary .mat-pseudo-checkbox-checked,
.toitsu-ng-default .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
.toitsu-ng-default .mat-pseudo-checkbox-checked,
.toitsu-ng-default .mat-pseudo-checkbox-indeterminate,
.toitsu-ng-default .mat-accent .mat-pseudo-checkbox-checked,
.toitsu-ng-default .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.toitsu-ng-default .mat-warn .mat-pseudo-checkbox-checked,
.toitsu-ng-default .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.toitsu-ng-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.toitsu-ng-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.toitsu-ng-default .mat-app-background, .toitsu-ng-default.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.toitsu-ng-default .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.toitsu-ng-default .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-badge {
  position: relative;
}
.toitsu-ng-default .mat-badge.mat-badge {
  overflow: visible;
}
.toitsu-ng-default .mat-badge-hidden .mat-badge-content {
  display: none;
}
.toitsu-ng-default .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.toitsu-ng-default .ng-animate-disabled .mat-badge-content,
.toitsu-ng-default .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.toitsu-ng-default .mat-badge-content.mat-badge-active {
  transform: none;
}
.toitsu-ng-default .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.toitsu-ng-default .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.toitsu-ng-default .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.toitsu-ng-default .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.toitsu-ng-default .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.toitsu-ng-default .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .toitsu-ng-default .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.toitsu-ng-default .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .toitsu-ng-default .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.toitsu-ng-default .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.toitsu-ng-default .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.toitsu-ng-default .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-button, .toitsu-ng-default .mat-icon-button, .toitsu-ng-default .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.toitsu-ng-default .mat-button.mat-primary, .toitsu-ng-default .mat-icon-button.mat-primary, .toitsu-ng-default .mat-stroked-button.mat-primary {
  color: #2196f3;
}
.toitsu-ng-default .mat-button.mat-accent, .toitsu-ng-default .mat-icon-button.mat-accent, .toitsu-ng-default .mat-stroked-button.mat-accent {
  color: #43a047;
}
.toitsu-ng-default .mat-button.mat-warn, .toitsu-ng-default .mat-icon-button.mat-warn, .toitsu-ng-default .mat-stroked-button.mat-warn {
  color: #f44336;
}
.toitsu-ng-default .mat-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-icon-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-icon-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-icon-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-icon-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-stroked-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-stroked-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-stroked-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-default .mat-icon-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-default .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-default .mat-icon-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-default .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.toitsu-ng-default .mat-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-default .mat-icon-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-default .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.toitsu-ng-default .mat-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-default .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-default .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.toitsu-ng-default .mat-button .mat-ripple-element, .toitsu-ng-default .mat-icon-button .mat-ripple-element, .toitsu-ng-default .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.toitsu-ng-default .mat-button-focus-overlay {
  background: black;
}
.toitsu-ng-default .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-flat-button, .toitsu-ng-default .mat-raised-button, .toitsu-ng-default .mat-fab, .toitsu-ng-default .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.toitsu-ng-default .mat-flat-button.mat-primary, .toitsu-ng-default .mat-raised-button.mat-primary, .toitsu-ng-default .mat-fab.mat-primary, .toitsu-ng-default .mat-mini-fab.mat-primary {
  color: white;
}
.toitsu-ng-default .mat-flat-button.mat-accent, .toitsu-ng-default .mat-raised-button.mat-accent, .toitsu-ng-default .mat-fab.mat-accent, .toitsu-ng-default .mat-mini-fab.mat-accent {
  color: white;
}
.toitsu-ng-default .mat-flat-button.mat-warn, .toitsu-ng-default .mat-raised-button.mat-warn, .toitsu-ng-default .mat-fab.mat-warn, .toitsu-ng-default .mat-mini-fab.mat-warn {
  color: white;
}
.toitsu-ng-default .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-flat-button.mat-primary, .toitsu-ng-default .mat-raised-button.mat-primary, .toitsu-ng-default .mat-fab.mat-primary, .toitsu-ng-default .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-flat-button.mat-accent, .toitsu-ng-default .mat-raised-button.mat-accent, .toitsu-ng-default .mat-fab.mat-accent, .toitsu-ng-default .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.toitsu-ng-default .mat-flat-button.mat-warn, .toitsu-ng-default .mat-raised-button.mat-warn, .toitsu-ng-default .mat-fab.mat-warn, .toitsu-ng-default .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.toitsu-ng-default .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-default .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-flat-button.mat-primary .mat-ripple-element, .toitsu-ng-default .mat-raised-button.mat-primary .mat-ripple-element, .toitsu-ng-default .mat-fab.mat-primary .mat-ripple-element, .toitsu-ng-default .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-flat-button.mat-accent .mat-ripple-element, .toitsu-ng-default .mat-raised-button.mat-accent .mat-ripple-element, .toitsu-ng-default .mat-fab.mat-accent .mat-ripple-element, .toitsu-ng-default .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-flat-button.mat-warn .mat-ripple-element, .toitsu-ng-default .mat-raised-button.mat-warn .mat-ripple-element, .toitsu-ng-default .mat-fab.mat-warn .mat-ripple-element, .toitsu-ng-default .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-stroked-button:not([class*=mat-elevation-z]), .toitsu-ng-default .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-fab:not([class*=mat-elevation-z]), .toitsu-ng-default .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .toitsu-ng-default .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .toitsu-ng-default .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.toitsu-ng-default .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.toitsu-ng-default .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.toitsu-ng-default .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.toitsu-ng-default .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.toitsu-ng-default .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.toitsu-ng-default [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.toitsu-ng-default .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.toitsu-ng-default .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.toitsu-ng-default .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.toitsu-ng-default .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.toitsu-ng-default .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toitsu-ng-default .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.toitsu-ng-default .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-checkbox-checkmark {
  fill: #fafafa;
}
.toitsu-ng-default .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.toitsu-ng-default .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.toitsu-ng-default .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .toitsu-ng-default .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .toitsu-ng-default .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.toitsu-ng-default .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .toitsu-ng-default .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.toitsu-ng-default .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .toitsu-ng-default .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.toitsu-ng-default .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.toitsu-ng-default .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-default .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.toitsu-ng-default .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
.toitsu-ng-default .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.toitsu-ng-default .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.toitsu-ng-default .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.toitsu-ng-default .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.toitsu-ng-default .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.toitsu-ng-default .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.toitsu-ng-default .mat-chip.mat-standard-chip::after {
  background: black;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default .mat-table {
  background: white;
}
.toitsu-ng-default .mat-table thead, .toitsu-ng-default .mat-table tbody, .toitsu-ng-default .mat-table tfoot,
.toitsu-ng-default mat-header-row, .toitsu-ng-default mat-row, .toitsu-ng-default mat-footer-row,
.toitsu-ng-default [mat-header-row], .toitsu-ng-default [mat-row], .toitsu-ng-default [mat-footer-row],
.toitsu-ng-default .mat-table-sticky {
  background: inherit;
}
.toitsu-ng-default mat-row, .toitsu-ng-default mat-header-row, .toitsu-ng-default mat-footer-row,
.toitsu-ng-default th.mat-header-cell, .toitsu-ng-default td.mat-cell, .toitsu-ng-default td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-cell, .toitsu-ng-default .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-datepicker-toggle,
.toitsu-ng-default .mat-datepicker-content .mat-calendar-next-button,
.toitsu-ng-default .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-calendar-table-header,
.toitsu-ng-default .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-calendar-body-cell-content,
.toitsu-ng-default .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.toitsu-ng-default .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.toitsu-ng-default .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.toitsu-ng-default .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
.toitsu-ng-default .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
.toitsu-ng-default .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.toitsu-ng-default .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.toitsu-ng-default .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-datepicker-toggle-active {
  color: #2196f3;
}
.toitsu-ng-default .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.toitsu-ng-default .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.toitsu-ng-default .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .toitsu-ng-default .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .toitsu-ng-default .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .toitsu-ng-default .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.toitsu-ng-default .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-expansion-panel-header-description,
.toitsu-ng-default .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.toitsu-ng-default .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.toitsu-ng-default .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-default .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.toitsu-ng-default .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.toitsu-ng-default .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.toitsu-ng-default .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.toitsu-ng-default .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
.toitsu-ng-default .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.toitsu-ng-default .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.toitsu-ng-default .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.toitsu-ng-default .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.toitsu-ng-default .mat-error {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-default .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-default .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.toitsu-ng-default .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.toitsu-ng-default .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.toitsu-ng-default .mat-icon.mat-primary {
  color: #2196f3;
}
.toitsu-ng-default .mat-icon.mat-accent {
  color: #43a047;
}
.toitsu-ng-default .mat-icon.mat-warn {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-input-element:disabled,
.toitsu-ng-default .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-input-element {
  caret-color: #2196f3;
}
.toitsu-ng-default .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.toitsu-ng-default .mat-form-field.mat-warn .mat-input-element,
.toitsu-ng-default .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.toitsu-ng-default .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.toitsu-ng-default .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-list-option:hover, .toitsu-ng-default .mat-list-option:focus,
.toitsu-ng-default .mat-nav-list .mat-list-item:hover,
.toitsu-ng-default .mat-nav-list .mat-list-item:focus,
.toitsu-ng-default .mat-action-list .mat-list-item:hover,
.toitsu-ng-default .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-list-single-selected-option, .toitsu-ng-default .mat-list-single-selected-option:hover, .toitsu-ng-default .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-menu-panel {
  background: white;
}
.toitsu-ng-default .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-menu-item[disabled],
.toitsu-ng-default .mat-menu-item[disabled] .mat-menu-submenu-icon,
.toitsu-ng-default .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-menu-item .mat-icon-no-color,
.toitsu-ng-default .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-menu-item:hover:not([disabled]),
.toitsu-ng-default .mat-menu-item.cdk-program-focused:not([disabled]),
.toitsu-ng-default .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.toitsu-ng-default .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-paginator {
  background: white;
}
.toitsu-ng-default .mat-paginator,
.toitsu-ng-default .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-paginator-decrement,
.toitsu-ng-default .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-paginator-first,
.toitsu-ng-default .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-icon-button[disabled] .mat-paginator-decrement,
.toitsu-ng-default .mat-icon-button[disabled] .mat-paginator-increment,
.toitsu-ng-default .mat-icon-button[disabled] .mat-paginator-first,
.toitsu-ng-default .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.toitsu-ng-default .mat-progress-bar-background {
  fill: #c4e1f8;
}
.toitsu-ng-default .mat-progress-bar-buffer {
  background-color: #c4e1f8;
}
.toitsu-ng-default .mat-progress-bar-fill::after {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.toitsu-ng-default .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.toitsu-ng-default .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.toitsu-ng-default .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.toitsu-ng-default .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.toitsu-ng-default .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.toitsu-ng-default .mat-progress-spinner circle, .toitsu-ng-default .mat-spinner circle {
  stroke: #2196f3;
}
.toitsu-ng-default .mat-progress-spinner.mat-accent circle, .toitsu-ng-default .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.toitsu-ng-default .mat-progress-spinner.mat-warn circle, .toitsu-ng-default .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.toitsu-ng-default .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
.toitsu-ng-default .mat-radio-button.mat-primary .mat-radio-inner-circle,
.toitsu-ng-default .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.toitsu-ng-default .mat-radio-button.mat-accent .mat-radio-inner-circle,
.toitsu-ng-default .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.toitsu-ng-default .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.toitsu-ng-default .mat-radio-button.mat-warn .mat-radio-inner-circle,
.toitsu-ng-default .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.toitsu-ng-default .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.toitsu-ng-default .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.toitsu-ng-default .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-default .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-default .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-select-panel {
  background: white;
}
.toitsu-ng-default .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
.toitsu-ng-default .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.toitsu-ng-default .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.toitsu-ng-default .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-drawer.mat-drawer-push {
  background-color: white;
}
.toitsu-ng-default .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.toitsu-ng-default [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.toitsu-ng-default [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.toitsu-ng-default .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.toitsu-ng-default .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.toitsu-ng-default .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
.toitsu-ng-default .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.toitsu-ng-default .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.toitsu-ng-default .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.toitsu-ng-default .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-default .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.toitsu-ng-default .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-slider.mat-primary .mat-slider-track-fill,
.toitsu-ng-default .mat-slider.mat-primary .mat-slider-thumb,
.toitsu-ng-default .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
.toitsu-ng-default .mat-slider.mat-accent .mat-slider-track-fill,
.toitsu-ng-default .mat-slider.mat-accent .mat-slider-thumb,
.toitsu-ng-default .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.toitsu-ng-default .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.toitsu-ng-default .mat-slider.mat-warn .mat-slider-track-fill,
.toitsu-ng-default .mat-slider.mat-warn .mat-slider-thumb,
.toitsu-ng-default .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.toitsu-ng-default .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.toitsu-ng-default .mat-slider:hover .mat-slider-track-background,
.toitsu-ng-default .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-slider.mat-slider-disabled .mat-slider-track-background,
.toitsu-ng-default .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.toitsu-ng-default .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.toitsu-ng-default .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.toitsu-ng-default .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.toitsu-ng-default .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .toitsu-ng-default .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .toitsu-ng-default .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-default .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.toitsu-ng-default .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-default .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-default .mat-step-header.cdk-keyboard-focused, .toitsu-ng-default .mat-step-header.cdk-program-focused, .toitsu-ng-default .mat-step-header:hover:not([aria-disabled]), .toitsu-ng-default .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .toitsu-ng-default .mat-step-header:hover {
    background: none;
  }
}
.toitsu-ng-default .mat-step-header .mat-step-label,
.toitsu-ng-default .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.toitsu-ng-default .mat-step-header .mat-step-icon-selected,
.toitsu-ng-default .mat-step-header .mat-step-icon-state-done,
.toitsu-ng-default .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.toitsu-ng-default .mat-step-header.mat-accent .mat-step-icon-selected,
.toitsu-ng-default .mat-step-header.mat-accent .mat-step-icon-state-done,
.toitsu-ng-default .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.toitsu-ng-default .mat-step-header.mat-warn .mat-step-icon-selected,
.toitsu-ng-default .mat-step-header.mat-warn .mat-step-icon-state-done,
.toitsu-ng-default .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.toitsu-ng-default .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.toitsu-ng-default .mat-stepper-horizontal, .toitsu-ng-default .mat-stepper-vertical {
  background-color: white;
}
.toitsu-ng-default .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-horizontal-stepper-header::before,
.toitsu-ng-default .mat-horizontal-stepper-header::after,
.toitsu-ng-default .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.toitsu-ng-default .mat-sort-header-arrow {
  color: #757575;
}
.toitsu-ng-default .mat-tab-nav-bar,
.toitsu-ng-default .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-tab-group-inverted-header .mat-tab-nav-bar,
.toitsu-ng-default .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.toitsu-ng-default .mat-tab-label, .toitsu-ng-default .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.toitsu-ng-default .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.toitsu-ng-default .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-primary .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 230, 201, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-accent .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.toitsu-ng-default .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-warn .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.toitsu-ng-default .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-default .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header, .toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-link-container, .toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 230, 201, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header, .toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-link-container, .toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header, .toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-link-container, .toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.toitsu-ng-default .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.toitsu-ng-default .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.toitsu-ng-default .mat-toolbar .mat-form-field-underline,
.toitsu-ng-default .mat-toolbar .mat-form-field-ripple,
.toitsu-ng-default .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.toitsu-ng-default .mat-toolbar .mat-form-field-label,
.toitsu-ng-default .mat-toolbar .mat-focused .mat-form-field-label,
.toitsu-ng-default .mat-toolbar .mat-select-value,
.toitsu-ng-default .mat-toolbar .mat-select-arrow,
.toitsu-ng-default .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.toitsu-ng-default .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.toitsu-ng-default .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.toitsu-ng-default .mat-tree {
  background: white;
}
.toitsu-ng-default .mat-tree-node,
.toitsu-ng-default .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.toitsu-ng-default .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .mat-simple-snackbar-action {
  color: #43a047;
}
.toitsu-ng-default .header {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .header-seperator {
  background-color: #43a047;
}
.toitsu-ng-default toitsu-ng-material-header-user .dropdown {
  color: rgba(0, 0, 0, 0.87);
  background: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default toitsu-ng-material-header-user .dropdown .list-item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-default toitsu-ng-material-header-user .dropdown .list-item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .footer-divider {
  background-color: #43a047;
}
.toitsu-ng-default .footer {
  background-color: #eeeeee;
  color: #616161;
}
.toitsu-ng-default .footer .mat-icon-button.active {
  color: #2196f3;
}
.toitsu-ng-default .sidenav {
  background-color: whitesmoke;
}
.toitsu-ng-default .sidenav .sidenav-header {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .sidenav-item.active {
  background-color: #2196f3;
  color: white !important;
}
.toitsu-ng-default .sidenav-item:hover {
  background-color: #2196f3;
  color: white !important;
}
.toitsu-ng-default .sidenav-header {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default .banner {
  display: block;
  padding: 1.5em;
  background-color: rgba(97, 97, 97, 0.2);
  border-left: 5px solid #616161;
  color: #616161;
}
.toitsu-ng-default .banner.success {
  background-color: rgba(0, 158, 63, 0.2);
  border-left: 5px solid #009e3f;
  color: #009e3f;
}
.toitsu-ng-default .banner.warn {
  background-color: rgba(244, 67, 54, 0.2);
  border-left: 5px solid #f44336;
  color: #f44336;
}
.toitsu-ng-default .block-ui-wrapper {
  background-color: rgba(0, 0, 0, 0.32) !important;
}
.toitsu-ng-default .block-ui-wrapper.active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.toitsu-ng-default .block-ui-spinner,
.toitsu-ng-default .block-ui-template {
  position: unset !important;
  top: unset !important;
  margin: unset !important;
  left: unset !important;
  right: unset !important;
  transform: unset !important;
}
.toitsu-ng-default .mat-raised-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-default .mat-raised-button mat-icon {
  border-radius: 50%;
  background-color: white;
  color: #2196f3;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-default .mat-raised-button mat-icon:first-child {
  margin-right: 1rem;
}
.toitsu-ng-default .mat-raised-button mat-icon:last-child {
  margin-left: 1rem;
}
.toitsu-ng-default .mat-raised-button.mat-button-disabled mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .mat-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-default .mat-button mat-icon {
  margin: 0 10px;
  border-radius: 50%;
  background-color: #2196f3;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-default .mat-fab {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toitsu-ng-default .mat-fab .mat-button-wrapper {
  padding: unset !important;
}
.toitsu-ng-default .mat-fab mat-icon {
  font-weight: bold;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.toitsu-ng-default .mat-form-field * {
  font: 400 1rem/1.2 var(--font-family) !important;
}
.toitsu-ng-default .orbit-spinner,
.toitsu-ng-default .orbit-spinner * {
  box-sizing: border-box;
}
.toitsu-ng-default .orbit-spinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  perspective: 800px;
}
.toitsu-ng-default .orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.toitsu-ng-default .orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 12000ms linear infinite;
  border-bottom: 3px solid #43a047;
}
.toitsu-ng-default .orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 12000ms linear infinite;
  border-right: 3px solid #43a047;
}
.toitsu-ng-default .orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 12000ms linear infinite;
  border-top: 3px solid #43a047;
}
@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.toitsu-ng-default .snackbar-primary {
  background-color: #2196f3 !important;
  color: white !important;
}
.toitsu-ng-default .snackbar-primary .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-default .snackbar-success {
  background-color: #43a047 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.toitsu-ng-default .snackbar-success .mat-simple-snackbar-action {
  color: rgba(0, 0, 0, 0.87) !important;
}
.toitsu-ng-default .snackbar-error {
  background-color: #f44336 !important;
  color: white !important;
}
.toitsu-ng-default .snackbar-error .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-default .mat-checkbox-label {
  white-space: normal;
}
.toitsu-ng-default .mat-select-panel .mat-option {
  line-height: unset;
  height: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: unset;
}
.toitsu-ng-default .mat-select-panel .mat-option .mat-option-text {
  white-space: unset;
}
.toitsu-ng-default .custom-search-fields .search-row mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-default .operator-option {
  text-align: center !important;
}
.toitsu-ng-default .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(67, 160, 71, 0.5);
}
.toitsu-ng-default .disabled-calendar-date .mat-calendar-body-cell-content,
.toitsu-ng-default .mat-calendar-body-disabled .mat-calendar-body-cell-content {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  background-color: unset;
}
.toitsu-ng-default .disabled-calendar-date {
  pointer-events: none;
}
.toitsu-ng-default .highlighted-calendar-date .mat-calendar-body-cell-content {
  color: #2196f3;
}
.toitsu-ng-default .timeframe.available {
  background-color: #43a047;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default .timeframe.available.selected {
  background-color: #2196f3;
}
.toitsu-ng-default .timeframe.unavailable {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default .booking-row {
  color: rgba(0, 0, 0, 0.87) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .booking-row:first-child {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default .error-message {
  color: #f44336;
}
.toitsu-ng-default a:not(.mat-raised-button):not(.mat-button) {
  color: #43a047;
  text-decoration: underline;
}
.toitsu-ng-default a:not(.mat-raised-button):not(.mat-button):visited {
  color: #4c2c92;
}
.toitsu-ng-default a:not(.mat-raised-button):not(.mat-button):hover {
  color: #2196f3;
}
.toitsu-ng-default.dark {
  /**
  **  Custom snackbar styles
   */
}
.toitsu-ng-default.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-option {
  color: white;
}
.toitsu-ng-default.dark .mat-option:hover:not(.mat-option-disabled), .toitsu-ng-default.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.toitsu-ng-default.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.toitsu-ng-default.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.toitsu-ng-default.dark .mat-primary .mat-pseudo-checkbox-checked,
.toitsu-ng-default.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2196f3;
}
.toitsu-ng-default.dark .mat-pseudo-checkbox-checked,
.toitsu-ng-default.dark .mat-pseudo-checkbox-indeterminate,
.toitsu-ng-default.dark .mat-accent .mat-pseudo-checkbox-checked,
.toitsu-ng-default.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.toitsu-ng-default.dark .mat-warn .mat-pseudo-checkbox-checked,
.toitsu-ng-default.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.toitsu-ng-default.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.toitsu-ng-default.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.toitsu-ng-default.dark .mat-app-background, .toitsu-ng-default.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.toitsu-ng-default.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.toitsu-ng-default.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.toitsu-ng-default.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.toitsu-ng-default.dark .mat-badge {
  position: relative;
}
.toitsu-ng-default.dark .mat-badge.mat-badge {
  overflow: visible;
}
.toitsu-ng-default.dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.toitsu-ng-default.dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.toitsu-ng-default.dark .ng-animate-disabled .mat-badge-content,
.toitsu-ng-default.dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.toitsu-ng-default.dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.toitsu-ng-default.dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.toitsu-ng-default.dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.toitsu-ng-default.dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.toitsu-ng-default.dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.toitsu-ng-default.dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.toitsu-ng-default.dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .toitsu-ng-default.dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.toitsu-ng-default.dark .mat-badge-content {
  color: white;
  background: #2196f3;
}
.cdk-high-contrast-active .toitsu-ng-default.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.toitsu-ng-default.dark .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.toitsu-ng-default.dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.toitsu-ng-default.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-button, .toitsu-ng-default.dark .mat-icon-button, .toitsu-ng-default.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.toitsu-ng-default.dark .mat-button.mat-primary, .toitsu-ng-default.dark .mat-icon-button.mat-primary, .toitsu-ng-default.dark .mat-stroked-button.mat-primary {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-button.mat-accent, .toitsu-ng-default.dark .mat-icon-button.mat-accent, .toitsu-ng-default.dark .mat-stroked-button.mat-accent {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-button.mat-warn, .toitsu-ng-default.dark .mat-icon-button.mat-warn, .toitsu-ng-default.dark .mat-stroked-button.mat-warn {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-icon-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-icon-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-icon-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-stroked-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-stroked-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-stroked-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-default.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.toitsu-ng-default.dark .mat-button .mat-ripple-element, .toitsu-ng-default.dark .mat-icon-button .mat-ripple-element, .toitsu-ng-default.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.toitsu-ng-default.dark .mat-button-focus-overlay {
  background: white;
}
.toitsu-ng-default.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-flat-button, .toitsu-ng-default.dark .mat-raised-button, .toitsu-ng-default.dark .mat-fab, .toitsu-ng-default.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.toitsu-ng-default.dark .mat-flat-button.mat-primary, .toitsu-ng-default.dark .mat-raised-button.mat-primary, .toitsu-ng-default.dark .mat-fab.mat-primary, .toitsu-ng-default.dark .mat-mini-fab.mat-primary {
  color: white;
}
.toitsu-ng-default.dark .mat-flat-button.mat-accent, .toitsu-ng-default.dark .mat-raised-button.mat-accent, .toitsu-ng-default.dark .mat-fab.mat-accent, .toitsu-ng-default.dark .mat-mini-fab.mat-accent {
  color: white;
}
.toitsu-ng-default.dark .mat-flat-button.mat-warn, .toitsu-ng-default.dark .mat-raised-button.mat-warn, .toitsu-ng-default.dark .mat-fab.mat-warn, .toitsu-ng-default.dark .mat-mini-fab.mat-warn {
  color: white;
}
.toitsu-ng-default.dark .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-flat-button.mat-primary, .toitsu-ng-default.dark .mat-raised-button.mat-primary, .toitsu-ng-default.dark .mat-fab.mat-primary, .toitsu-ng-default.dark .mat-mini-fab.mat-primary {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-flat-button.mat-accent, .toitsu-ng-default.dark .mat-raised-button.mat-accent, .toitsu-ng-default.dark .mat-fab.mat-accent, .toitsu-ng-default.dark .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-flat-button.mat-warn, .toitsu-ng-default.dark .mat-raised-button.mat-warn, .toitsu-ng-default.dark .mat-fab.mat-warn, .toitsu-ng-default.dark .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-default.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-flat-button.mat-primary .mat-ripple-element, .toitsu-ng-default.dark .mat-raised-button.mat-primary .mat-ripple-element, .toitsu-ng-default.dark .mat-fab.mat-primary .mat-ripple-element, .toitsu-ng-default.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-flat-button.mat-accent .mat-ripple-element, .toitsu-ng-default.dark .mat-raised-button.mat-accent .mat-ripple-element, .toitsu-ng-default.dark .mat-fab.mat-accent .mat-ripple-element, .toitsu-ng-default.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-flat-button.mat-warn .mat-ripple-element, .toitsu-ng-default.dark .mat-raised-button.mat-warn .mat-ripple-element, .toitsu-ng-default.dark .mat-fab.mat-warn .mat-ripple-element, .toitsu-ng-default.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-stroked-button:not([class*=mat-elevation-z]), .toitsu-ng-default.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-fab:not([class*=mat-elevation-z]), .toitsu-ng-default.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .toitsu-ng-default.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .toitsu-ng-default.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.toitsu-ng-default.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.toitsu-ng-default.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.toitsu-ng-default.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.toitsu-ng-default.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.toitsu-ng-default.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.toitsu-ng-default.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.toitsu-ng-default.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.toitsu-ng-default.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.toitsu-ng-default.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.toitsu-ng-default.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.toitsu-ng-default.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.toitsu-ng-default.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toitsu-ng-default.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.toitsu-ng-default.dark .mat-card {
  background: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.toitsu-ng-default.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.toitsu-ng-default.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.toitsu-ng-default.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .toitsu-ng-default.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .toitsu-ng-default.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .toitsu-ng-default.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .toitsu-ng-default.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.toitsu-ng-default.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.toitsu-ng-default.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-default.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.toitsu-ng-default.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #2196f3;
}
.toitsu-ng-default.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.toitsu-ng-default.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.toitsu-ng-default.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.toitsu-ng-default.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-table {
  background: #424242;
}
.toitsu-ng-default.dark .mat-table thead, .toitsu-ng-default.dark .mat-table tbody, .toitsu-ng-default.dark .mat-table tfoot,
.toitsu-ng-default.dark mat-header-row, .toitsu-ng-default.dark mat-row, .toitsu-ng-default.dark mat-footer-row,
.toitsu-ng-default.dark [mat-header-row], .toitsu-ng-default.dark [mat-row], .toitsu-ng-default.dark [mat-footer-row],
.toitsu-ng-default.dark .mat-table-sticky {
  background: inherit;
}
.toitsu-ng-default.dark mat-row, .toitsu-ng-default.dark mat-header-row, .toitsu-ng-default.dark mat-footer-row,
.toitsu-ng-default.dark th.mat-header-cell, .toitsu-ng-default.dark td.mat-cell, .toitsu-ng-default.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-cell, .toitsu-ng-default.dark .mat-footer-cell {
  color: white;
}
.toitsu-ng-default.dark .mat-calendar-arrow {
  fill: white;
}
.toitsu-ng-default.dark .mat-datepicker-toggle,
.toitsu-ng-default.dark .mat-datepicker-content .mat-calendar-next-button,
.toitsu-ng-default.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.toitsu-ng-default.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-calendar-table-header,
.toitsu-ng-default.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-calendar-body-cell-content,
.toitsu-ng-default.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.toitsu-ng-default.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.toitsu-ng-default.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-calendar-body-in-range::before {
  background: rgba(33, 150, 243, 0.2);
}
.toitsu-ng-default.dark .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default.dark .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(33, 150, 243, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default.dark .mat-calendar-body-selected {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(33, 150, 243, 0.4);
}
.toitsu-ng-default.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(33, 150, 243, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(33, 150, 243, 0.3);
  }
}
.toitsu-ng-default.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-default.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-default.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.toitsu-ng-default.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-datepicker-toggle-active {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .toitsu-ng-default.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .toitsu-ng-default.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .toitsu-ng-default.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.toitsu-ng-default.dark .mat-expansion-panel-header-title {
  color: white;
}
.toitsu-ng-default.dark .mat-expansion-panel-header-description,
.toitsu-ng-default.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.toitsu-ng-default.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.toitsu-ng-default.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field-ripple {
  background-color: white;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.toitsu-ng-default.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.toitsu-ng-default.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-error {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-default.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-default.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-default.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.toitsu-ng-default.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.toitsu-ng-default.dark .mat-icon.mat-primary {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-icon.mat-accent {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-icon.mat-warn {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-input-element:disabled,
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-input-element {
  caret-color: #2196f3;
}
.toitsu-ng-default.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-default.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field.mat-warn .mat-input-element,
.toitsu-ng-default.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-list-base .mat-list-item {
  color: white;
}
.toitsu-ng-default.dark .mat-list-base .mat-list-option {
  color: white;
}
.toitsu-ng-default.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-list-option:hover, .toitsu-ng-default.dark .mat-list-option:focus,
.toitsu-ng-default.dark .mat-nav-list .mat-list-item:hover,
.toitsu-ng-default.dark .mat-nav-list .mat-list-item:focus,
.toitsu-ng-default.dark .mat-action-list .mat-list-item:hover,
.toitsu-ng-default.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .mat-list-single-selected-option, .toitsu-ng-default.dark .mat-list-single-selected-option:hover, .toitsu-ng-default.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-menu-panel {
  background: #424242;
}
.toitsu-ng-default.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.toitsu-ng-default.dark .mat-menu-item[disabled],
.toitsu-ng-default.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.toitsu-ng-default.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-menu-item .mat-icon-no-color,
.toitsu-ng-default.dark .mat-menu-submenu-icon {
  color: white;
}
.toitsu-ng-default.dark .mat-menu-item:hover:not([disabled]),
.toitsu-ng-default.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.toitsu-ng-default.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.toitsu-ng-default.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .mat-paginator {
  background: #424242;
}
.toitsu-ng-default.dark .mat-paginator,
.toitsu-ng-default.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-paginator-decrement,
.toitsu-ng-default.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.toitsu-ng-default.dark .mat-paginator-first,
.toitsu-ng-default.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.toitsu-ng-default.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.toitsu-ng-default.dark .mat-icon-button[disabled] .mat-paginator-increment,
.toitsu-ng-default.dark .mat-icon-button[disabled] .mat-paginator-first,
.toitsu-ng-default.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-progress-bar-background {
  fill: #2c4a61;
}
.toitsu-ng-default.dark .mat-progress-bar-buffer {
  background-color: #2c4a61;
}
.toitsu-ng-default.dark .mat-progress-bar-fill::after {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #354c36;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #354c36;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #613532;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #613532;
}
.toitsu-ng-default.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-progress-spinner circle, .toitsu-ng-default.dark .mat-spinner circle {
  stroke: #2196f3;
}
.toitsu-ng-default.dark .mat-progress-spinner.mat-accent circle, .toitsu-ng-default.dark .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.toitsu-ng-default.dark .mat-progress-spinner.mat-warn circle, .toitsu-ng-default.dark .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.toitsu-ng-default.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2196f3;
}
.toitsu-ng-default.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.toitsu-ng-default.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.toitsu-ng-default.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.toitsu-ng-default.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.toitsu-ng-default.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.toitsu-ng-default.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-default.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-default.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.toitsu-ng-default.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.toitsu-ng-default.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-default.dark .mat-select-value {
  color: white;
}
.toitsu-ng-default.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-select-panel {
  background: #424242;
}
.toitsu-ng-default.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2196f3;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.toitsu-ng-default.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.toitsu-ng-default.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.toitsu-ng-default.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.toitsu-ng-default.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.toitsu-ng-default.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.toitsu-ng-default.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(33, 150, 243, 0.54);
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.toitsu-ng-default.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-default.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.toitsu-ng-default.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-primary .mat-slider-track-fill,
.toitsu-ng-default.dark .mat-slider.mat-primary .mat-slider-thumb,
.toitsu-ng-default.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(33, 150, 243, 0.2);
}
.toitsu-ng-default.dark .mat-slider.mat-accent .mat-slider-track-fill,
.toitsu-ng-default.dark .mat-slider.mat-accent .mat-slider-thumb,
.toitsu-ng-default.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.toitsu-ng-default.dark .mat-slider.mat-warn .mat-slider-track-fill,
.toitsu-ng-default.dark .mat-slider.mat-warn .mat-slider-thumb,
.toitsu-ng-default.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-default.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.toitsu-ng-default.dark .mat-slider:hover .mat-slider-track-background,
.toitsu-ng-default.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.toitsu-ng-default.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.toitsu-ng-default.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .toitsu-ng-default.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .toitsu-ng-default.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-default.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-default.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-default.dark .mat-step-header.cdk-keyboard-focused, .toitsu-ng-default.dark .mat-step-header.cdk-program-focused, .toitsu-ng-default.dark .mat-step-header:hover:not([aria-disabled]), .toitsu-ng-default.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .toitsu-ng-default.dark .mat-step-header:hover {
    background: none;
  }
}
.toitsu-ng-default.dark .mat-step-header .mat-step-label,
.toitsu-ng-default.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-default.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.toitsu-ng-default.dark .mat-step-header .mat-step-icon-selected,
.toitsu-ng-default.dark .mat-step-header .mat-step-icon-state-done,
.toitsu-ng-default.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default.dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.toitsu-ng-default.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.toitsu-ng-default.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.toitsu-ng-default.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.toitsu-ng-default.dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.toitsu-ng-default.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.toitsu-ng-default.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.toitsu-ng-default.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.toitsu-ng-default.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.toitsu-ng-default.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.toitsu-ng-default.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.toitsu-ng-default.dark .mat-stepper-horizontal, .toitsu-ng-default.dark .mat-stepper-vertical {
  background-color: #424242;
}
.toitsu-ng-default.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-horizontal-stepper-header::before,
.toitsu-ng-default.dark .mat-horizontal-stepper-header::after,
.toitsu-ng-default.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.toitsu-ng-default.dark .mat-tab-nav-bar,
.toitsu-ng-default.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.toitsu-ng-default.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.toitsu-ng-default.dark .mat-tab-label, .toitsu-ng-default.dark .mat-tab-link {
  color: white;
}
.toitsu-ng-default.dark .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.toitsu-ng-default.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.toitsu-ng-default.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.toitsu-ng-default.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-primary .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 230, 201, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-accent .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-warn .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(187, 222, 251, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(200, 230, 201, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-default.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-default.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.toitsu-ng-default.dark .mat-toolbar.mat-primary {
  background: #2196f3;
  color: white;
}
.toitsu-ng-default.dark .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.toitsu-ng-default.dark .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.toitsu-ng-default.dark .mat-toolbar .mat-form-field-underline,
.toitsu-ng-default.dark .mat-toolbar .mat-form-field-ripple,
.toitsu-ng-default.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.toitsu-ng-default.dark .mat-toolbar .mat-form-field-label,
.toitsu-ng-default.dark .mat-toolbar .mat-focused .mat-form-field-label,
.toitsu-ng-default.dark .mat-toolbar .mat-select-value,
.toitsu-ng-default.dark .mat-toolbar .mat-select-arrow,
.toitsu-ng-default.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.toitsu-ng-default.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.toitsu-ng-default.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.toitsu-ng-default.dark .mat-tree {
  background: #424242;
}
.toitsu-ng-default.dark .mat-tree-node,
.toitsu-ng-default.dark .mat-nested-tree-node {
  color: white;
}
.toitsu-ng-default.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark .mat-simple-snackbar-action {
  color: inherit;
}
.toitsu-ng-default.dark .header {
  background-color: #2196f3;
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .header-seperator {
  background-color: #43a047;
}
.toitsu-ng-default.dark toitsu-ng-material-header-user .dropdown {
  color: white;
  background: #424242;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-default.dark toitsu-ng-material-header-user .dropdown .list-item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark toitsu-ng-material-header-user .dropdown .list-item-icon {
  color: white;
}
.toitsu-ng-default.dark .footer-divider {
  background-color: #43a047;
}
.toitsu-ng-default.dark .footer {
  background-color: #eeeeee;
  color: #616161;
  background-color: #383838;
}
.toitsu-ng-default.dark .footer .mat-icon-button.active {
  color: #2196f3;
}
.toitsu-ng-default.dark .sidenav {
  background-color: #2b2b2b;
}
.toitsu-ng-default.dark .sidenav .sidenav-header {
  background-color: #2196f3;
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-default.dark .sidenav-item.active {
  background-color: #2196f3;
  color: white !important;
}
.toitsu-ng-default.dark .sidenav-item:hover {
  background-color: #2196f3;
  color: white !important;
}
.toitsu-ng-default.dark .sidenav-header {
  background-color: #2196f3;
  color: white;
}
.toitsu-ng-default.dark .banner {
  display: block;
  padding: 1.5em;
  background-color: rgba(97, 97, 97, 0.2);
  border-left: 5px solid #616161;
  color: #616161;
}
.toitsu-ng-default.dark .banner.success {
  background-color: rgba(0, 158, 63, 0.2);
  border-left: 5px solid #009e3f;
  color: #009e3f;
}
.toitsu-ng-default.dark .banner.warn {
  background-color: rgba(244, 67, 54, 0.2);
  border-left: 5px solid #f44336;
  color: #f44336;
}
.toitsu-ng-default.dark .block-ui-wrapper {
  background-color: rgba(0, 0, 0, 0.32) !important;
}
.toitsu-ng-default.dark .block-ui-wrapper.active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.toitsu-ng-default.dark .block-ui-spinner,
.toitsu-ng-default.dark .block-ui-template {
  position: unset !important;
  top: unset !important;
  margin: unset !important;
  left: unset !important;
  right: unset !important;
  transform: unset !important;
}
.toitsu-ng-default.dark .mat-raised-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-default.dark .mat-raised-button mat-icon {
  border-radius: 50%;
  background-color: white;
  color: #2196f3;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-default.dark .mat-raised-button mat-icon:first-child {
  margin-right: 1rem;
}
.toitsu-ng-default.dark .mat-raised-button mat-icon:last-child {
  margin-left: 1rem;
}
.toitsu-ng-default.dark .mat-raised-button.mat-button-disabled mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .mat-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-default.dark .mat-button mat-icon {
  margin: 0 10px;
  border-radius: 50%;
  background-color: #2196f3;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-default.dark .mat-fab {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toitsu-ng-default.dark .mat-fab .mat-button-wrapper {
  padding: unset !important;
}
.toitsu-ng-default.dark .mat-fab mat-icon {
  font-weight: bold;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.toitsu-ng-default.dark .mat-form-field * {
  font: 400 1rem/1.2 var(--font-family) !important;
}
.toitsu-ng-default.dark .orbit-spinner,
.toitsu-ng-default.dark .orbit-spinner * {
  box-sizing: border-box;
}
.toitsu-ng-default.dark .orbit-spinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  perspective: 800px;
}
.toitsu-ng-default.dark .orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.toitsu-ng-default.dark .orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 12000ms linear infinite;
  border-bottom: 3px solid #43a047;
}
.toitsu-ng-default.dark .orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 12000ms linear infinite;
  border-right: 3px solid #43a047;
}
.toitsu-ng-default.dark .orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 12000ms linear infinite;
  border-top: 3px solid #43a047;
}
@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.toitsu-ng-default.dark .snackbar-primary {
  background-color: #2196f3 !important;
  color: white !important;
}
.toitsu-ng-default.dark .snackbar-primary .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-default.dark .snackbar-success {
  background-color: #43a047 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.toitsu-ng-default.dark .snackbar-success .mat-simple-snackbar-action {
  color: rgba(0, 0, 0, 0.87) !important;
}
.toitsu-ng-default.dark .snackbar-error {
  background-color: #f44336 !important;
  color: white !important;
}
.toitsu-ng-default.dark .snackbar-error .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-default.dark .mat-checkbox-label {
  white-space: normal;
}
.toitsu-ng-default.dark .mat-select-panel .mat-option {
  line-height: unset;
  height: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: unset;
}
.toitsu-ng-default.dark .mat-select-panel .mat-option .mat-option-text {
  white-space: unset;
}
.toitsu-ng-default.dark .custom-search-fields .search-row mat-icon {
  color: white;
}
.toitsu-ng-default.dark .operator-option {
  text-align: center !important;
}
.toitsu-ng-default.dark .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(67, 160, 71, 0.5);
}
.toitsu-ng-default.dark .disabled-calendar-date .mat-calendar-body-cell-content,
.toitsu-ng-default.dark .mat-calendar-body-disabled .mat-calendar-body-cell-content {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5);
  background-color: unset;
}
.toitsu-ng-default.dark .disabled-calendar-date {
  pointer-events: none;
}
.toitsu-ng-default.dark .highlighted-calendar-date .mat-calendar-body-cell-content {
  color: #2196f3;
}
.toitsu-ng-default.dark .timeframe.available {
  background-color: #43a047;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-default.dark .timeframe.available.selected {
  background-color: #2196f3;
}
.toitsu-ng-default.dark .timeframe.unavailable {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-default.dark .booking-row {
  color: white !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .booking-row:first-child {
  border-top: 2px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-default.dark .error-message {
  color: #f44336;
}
.toitsu-ng-default.dark a:not(.mat-raised-button):not(.mat-button) {
  color: #43a047;
  text-decoration: underline;
}
.toitsu-ng-default.dark a:not(.mat-raised-button):not(.mat-button):visited {
  color: #4c2c92;
}
.toitsu-ng-default.dark a:not(.mat-raised-button):not(.mat-button):hover {
  color: #2196f3;
}

.toitsu-ng-gov {
  /**
  **  Custom snackbar styles
   */
}
.toitsu-ng-gov .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.toitsu-ng-gov .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-option:hover:not(.mat-option-disabled), .toitsu-ng-gov .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #003476;
}
.toitsu-ng-gov .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #046ec5;
}
.toitsu-ng-gov .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.toitsu-ng-gov .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.toitsu-ng-gov .mat-primary .mat-pseudo-checkbox-checked,
.toitsu-ng-gov .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #003476;
}
.toitsu-ng-gov .mat-pseudo-checkbox-checked,
.toitsu-ng-gov .mat-pseudo-checkbox-indeterminate,
.toitsu-ng-gov .mat-accent .mat-pseudo-checkbox-checked,
.toitsu-ng-gov .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #046ec5;
}
.toitsu-ng-gov .mat-warn .mat-pseudo-checkbox-checked,
.toitsu-ng-gov .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ca2e2e;
}
.toitsu-ng-gov .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.toitsu-ng-gov .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.toitsu-ng-gov .mat-app-background, .toitsu-ng-gov.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.toitsu-ng-gov .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.toitsu-ng-gov .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-badge {
  position: relative;
}
.toitsu-ng-gov .mat-badge.mat-badge {
  overflow: visible;
}
.toitsu-ng-gov .mat-badge-hidden .mat-badge-content {
  display: none;
}
.toitsu-ng-gov .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.toitsu-ng-gov .ng-animate-disabled .mat-badge-content,
.toitsu-ng-gov .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.toitsu-ng-gov .mat-badge-content.mat-badge-active {
  transform: none;
}
.toitsu-ng-gov .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.toitsu-ng-gov .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.toitsu-ng-gov .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.toitsu-ng-gov .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.toitsu-ng-gov .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.toitsu-ng-gov .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .toitsu-ng-gov .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.toitsu-ng-gov .mat-badge-content {
  color: white;
  background: #003476;
}
.cdk-high-contrast-active .toitsu-ng-gov .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.toitsu-ng-gov .mat-badge-accent .mat-badge-content {
  background: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-badge-warn .mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #ca2e2e;
}
.toitsu-ng-gov .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-button, .toitsu-ng-gov .mat-icon-button, .toitsu-ng-gov .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.toitsu-ng-gov .mat-button.mat-primary, .toitsu-ng-gov .mat-icon-button.mat-primary, .toitsu-ng-gov .mat-stroked-button.mat-primary {
  color: #003476;
}
.toitsu-ng-gov .mat-button.mat-accent, .toitsu-ng-gov .mat-icon-button.mat-accent, .toitsu-ng-gov .mat-stroked-button.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov .mat-button.mat-warn, .toitsu-ng-gov .mat-icon-button.mat-warn, .toitsu-ng-gov .mat-stroked-button.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-icon-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-icon-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-icon-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-icon-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-stroked-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-stroked-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-stroked-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-gov .mat-icon-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-gov .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #003476;
}
.toitsu-ng-gov .mat-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-gov .mat-icon-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-gov .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-gov .mat-icon-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-gov .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-gov .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-gov .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.toitsu-ng-gov .mat-button .mat-ripple-element, .toitsu-ng-gov .mat-icon-button .mat-ripple-element, .toitsu-ng-gov .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.toitsu-ng-gov .mat-button-focus-overlay {
  background: black;
}
.toitsu-ng-gov .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-flat-button, .toitsu-ng-gov .mat-raised-button, .toitsu-ng-gov .mat-fab, .toitsu-ng-gov .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.toitsu-ng-gov .mat-flat-button.mat-primary, .toitsu-ng-gov .mat-raised-button.mat-primary, .toitsu-ng-gov .mat-fab.mat-primary, .toitsu-ng-gov .mat-mini-fab.mat-primary {
  color: white;
}
.toitsu-ng-gov .mat-flat-button.mat-accent, .toitsu-ng-gov .mat-raised-button.mat-accent, .toitsu-ng-gov .mat-fab.mat-accent, .toitsu-ng-gov .mat-mini-fab.mat-accent {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-flat-button.mat-warn, .toitsu-ng-gov .mat-raised-button.mat-warn, .toitsu-ng-gov .mat-fab.mat-warn, .toitsu-ng-gov .mat-mini-fab.mat-warn {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-flat-button.mat-primary, .toitsu-ng-gov .mat-raised-button.mat-primary, .toitsu-ng-gov .mat-fab.mat-primary, .toitsu-ng-gov .mat-mini-fab.mat-primary {
  background-color: #003476;
}
.toitsu-ng-gov .mat-flat-button.mat-accent, .toitsu-ng-gov .mat-raised-button.mat-accent, .toitsu-ng-gov .mat-fab.mat-accent, .toitsu-ng-gov .mat-mini-fab.mat-accent {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-flat-button.mat-warn, .toitsu-ng-gov .mat-raised-button.mat-warn, .toitsu-ng-gov .mat-fab.mat-warn, .toitsu-ng-gov .mat-mini-fab.mat-warn {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-flat-button.mat-primary .mat-ripple-element, .toitsu-ng-gov .mat-raised-button.mat-primary .mat-ripple-element, .toitsu-ng-gov .mat-fab.mat-primary .mat-ripple-element, .toitsu-ng-gov .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-flat-button.mat-accent .mat-ripple-element, .toitsu-ng-gov .mat-raised-button.mat-accent .mat-ripple-element, .toitsu-ng-gov .mat-fab.mat-accent .mat-ripple-element, .toitsu-ng-gov .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-flat-button.mat-warn .mat-ripple-element, .toitsu-ng-gov .mat-raised-button.mat-warn .mat-ripple-element, .toitsu-ng-gov .mat-fab.mat-warn .mat-ripple-element, .toitsu-ng-gov .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-stroked-button:not([class*=mat-elevation-z]), .toitsu-ng-gov .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-fab:not([class*=mat-elevation-z]), .toitsu-ng-gov .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .toitsu-ng-gov .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .toitsu-ng-gov .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.toitsu-ng-gov .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.toitsu-ng-gov .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.toitsu-ng-gov .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.toitsu-ng-gov .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.toitsu-ng-gov .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.toitsu-ng-gov [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.toitsu-ng-gov .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.toitsu-ng-gov .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.toitsu-ng-gov .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.toitsu-ng-gov .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.toitsu-ng-gov .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toitsu-ng-gov .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.toitsu-ng-gov .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-checkbox-checkmark {
  fill: #fafafa;
}
.toitsu-ng-gov .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.toitsu-ng-gov .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.toitsu-ng-gov .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .toitsu-ng-gov .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #003476;
}
.toitsu-ng-gov .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .toitsu-ng-gov .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .toitsu-ng-gov .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .toitsu-ng-gov .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.toitsu-ng-gov .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.toitsu-ng-gov .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-gov .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.toitsu-ng-gov .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #003476;
}
.toitsu-ng-gov .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.toitsu-ng-gov .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #046ec5;
}
.toitsu-ng-gov .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.toitsu-ng-gov .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ca2e2e;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip::after {
  background: black;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.toitsu-ng-gov .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov .mat-table {
  background: white;
}
.toitsu-ng-gov .mat-table thead, .toitsu-ng-gov .mat-table tbody, .toitsu-ng-gov .mat-table tfoot,
.toitsu-ng-gov mat-header-row, .toitsu-ng-gov mat-row, .toitsu-ng-gov mat-footer-row,
.toitsu-ng-gov [mat-header-row], .toitsu-ng-gov [mat-row], .toitsu-ng-gov [mat-footer-row],
.toitsu-ng-gov .mat-table-sticky {
  background: inherit;
}
.toitsu-ng-gov mat-row, .toitsu-ng-gov mat-header-row, .toitsu-ng-gov mat-footer-row,
.toitsu-ng-gov th.mat-header-cell, .toitsu-ng-gov td.mat-cell, .toitsu-ng-gov td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-cell, .toitsu-ng-gov .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-datepicker-toggle,
.toitsu-ng-gov .mat-datepicker-content .mat-calendar-next-button,
.toitsu-ng-gov .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-calendar-table-header,
.toitsu-ng-gov .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-calendar-body-cell-content,
.toitsu-ng-gov .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.toitsu-ng-gov .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.toitsu-ng-gov .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.toitsu-ng-gov .mat-calendar-body-in-range::before {
  background: rgba(0, 52, 118, 0.2);
}
.toitsu-ng-gov .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 52, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 52, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov .mat-calendar-body-selected {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 52, 118, 0.4);
}
.toitsu-ng-gov .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-gov .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 52, 118, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 52, 118, 0.3);
  }
}
.toitsu-ng-gov .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(4, 110, 197, 0.2);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(4, 110, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(4, 110, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(4, 110, 197, 0.4);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(4, 110, 197, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(4, 110, 197, 0.3);
  }
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(202, 46, 46, 0.2);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(202, 46, 46, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(202, 46, 46, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(202, 46, 46, 0.4);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(202, 46, 46, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(202, 46, 46, 0.3);
  }
}
.toitsu-ng-gov .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-datepicker-toggle-active {
  color: #003476;
}
.toitsu-ng-gov .mat-datepicker-toggle-active.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov .mat-datepicker-toggle-active.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .toitsu-ng-gov .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .toitsu-ng-gov .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .toitsu-ng-gov .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.toitsu-ng-gov .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-expansion-panel-header-description,
.toitsu-ng-gov .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.toitsu-ng-gov .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.toitsu-ng-gov .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-gov .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-label {
  color: #003476;
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-focused .mat-form-field-required-marker {
  color: #046ec5;
}
.toitsu-ng-gov .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #003476;
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #003476;
}
.toitsu-ng-gov .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #046ec5;
}
.toitsu-ng-gov .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.toitsu-ng-gov .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.toitsu-ng-gov .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-error {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-gov .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-gov .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.toitsu-ng-gov .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.toitsu-ng-gov .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #003476;
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #046ec5;
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.toitsu-ng-gov .mat-icon.mat-primary {
  color: #003476;
}
.toitsu-ng-gov .mat-icon.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov .mat-icon.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-input-element:disabled,
.toitsu-ng-gov .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-input-element {
  caret-color: #003476;
}
.toitsu-ng-gov .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-form-field.mat-accent .mat-input-element {
  caret-color: #046ec5;
}
.toitsu-ng-gov .mat-form-field.mat-warn .mat-input-element,
.toitsu-ng-gov .mat-form-field-invalid .mat-input-element {
  caret-color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-list-option:hover, .toitsu-ng-gov .mat-list-option:focus,
.toitsu-ng-gov .mat-nav-list .mat-list-item:hover,
.toitsu-ng-gov .mat-nav-list .mat-list-item:focus,
.toitsu-ng-gov .mat-action-list .mat-list-item:hover,
.toitsu-ng-gov .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-list-single-selected-option, .toitsu-ng-gov .mat-list-single-selected-option:hover, .toitsu-ng-gov .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-menu-panel {
  background: white;
}
.toitsu-ng-gov .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-menu-item[disabled],
.toitsu-ng-gov .mat-menu-item[disabled] .mat-menu-submenu-icon,
.toitsu-ng-gov .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-menu-item .mat-icon-no-color,
.toitsu-ng-gov .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-menu-item:hover:not([disabled]),
.toitsu-ng-gov .mat-menu-item.cdk-program-focused:not([disabled]),
.toitsu-ng-gov .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.toitsu-ng-gov .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-paginator {
  background: white;
}
.toitsu-ng-gov .mat-paginator,
.toitsu-ng-gov .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-paginator-decrement,
.toitsu-ng-gov .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-paginator-first,
.toitsu-ng-gov .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-icon-button[disabled] .mat-paginator-decrement,
.toitsu-ng-gov .mat-icon-button[disabled] .mat-paginator-increment,
.toitsu-ng-gov .mat-icon-button[disabled] .mat-paginator-first,
.toitsu-ng-gov .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-progress-bar-background {
  fill: #bcc9d9;
}
.toitsu-ng-gov .mat-progress-bar-buffer {
  background-color: #bcc9d9;
}
.toitsu-ng-gov .mat-progress-bar-fill::after {
  background-color: #003476;
}
.toitsu-ng-gov .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bdd7ed;
}
.toitsu-ng-gov .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bdd7ed;
}
.toitsu-ng-gov .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #eec7c7;
}
.toitsu-ng-gov .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #eec7c7;
}
.toitsu-ng-gov .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-progress-spinner circle, .toitsu-ng-gov .mat-spinner circle {
  stroke: #003476;
}
.toitsu-ng-gov .mat-progress-spinner.mat-accent circle, .toitsu-ng-gov .mat-spinner.mat-accent circle {
  stroke: #046ec5;
}
.toitsu-ng-gov .mat-progress-spinner.mat-warn circle, .toitsu-ng-gov .mat-spinner.mat-warn circle {
  stroke: #ca2e2e;
}
.toitsu-ng-gov .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003476;
}
.toitsu-ng-gov .mat-radio-button.mat-primary .mat-radio-inner-circle,
.toitsu-ng-gov .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #003476;
}
.toitsu-ng-gov .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #046ec5;
}
.toitsu-ng-gov .mat-radio-button.mat-accent .mat-radio-inner-circle,
.toitsu-ng-gov .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ca2e2e;
}
.toitsu-ng-gov .mat-radio-button.mat-warn .mat-radio-inner-circle,
.toitsu-ng-gov .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.toitsu-ng-gov .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.toitsu-ng-gov .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-gov .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.toitsu-ng-gov .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-select-panel {
  background: white;
}
.toitsu-ng-gov .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #003476;
}
.toitsu-ng-gov .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #046ec5;
}
.toitsu-ng-gov .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-drawer.mat-drawer-push {
  background-color: white;
}
.toitsu-ng-gov .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.toitsu-ng-gov [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.toitsu-ng-gov [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.toitsu-ng-gov .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(4, 110, 197, 0.54);
}
.toitsu-ng-gov .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #003476;
}
.toitsu-ng-gov .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 52, 118, 0.54);
}
.toitsu-ng-gov .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #003476;
}
.toitsu-ng-gov .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(202, 46, 46, 0.54);
}
.toitsu-ng-gov .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.toitsu-ng-gov .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.toitsu-ng-gov .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-slider.mat-primary .mat-slider-track-fill,
.toitsu-ng-gov .mat-slider.mat-primary .mat-slider-thumb,
.toitsu-ng-gov .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #003476;
}
.toitsu-ng-gov .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-gov .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 52, 118, 0.2);
}
.toitsu-ng-gov .mat-slider.mat-accent .mat-slider-track-fill,
.toitsu-ng-gov .mat-slider.mat-accent .mat-slider-thumb,
.toitsu-ng-gov .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(4, 110, 197, 0.2);
}
.toitsu-ng-gov .mat-slider.mat-warn .mat-slider-track-fill,
.toitsu-ng-gov .mat-slider.mat-warn .mat-slider-thumb,
.toitsu-ng-gov .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(202, 46, 46, 0.2);
}
.toitsu-ng-gov .mat-slider:hover .mat-slider-track-background,
.toitsu-ng-gov .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-slider.mat-slider-disabled .mat-slider-track-background,
.toitsu-ng-gov .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.toitsu-ng-gov .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.toitsu-ng-gov .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.toitsu-ng-gov .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .toitsu-ng-gov .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .toitsu-ng-gov .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.toitsu-ng-gov .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.toitsu-ng-gov .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-gov .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-gov .mat-step-header.cdk-keyboard-focused, .toitsu-ng-gov .mat-step-header.cdk-program-focused, .toitsu-ng-gov .mat-step-header:hover:not([aria-disabled]), .toitsu-ng-gov .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .toitsu-ng-gov .mat-step-header:hover {
    background: none;
  }
}
.toitsu-ng-gov .mat-step-header .mat-step-label,
.toitsu-ng-gov .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.toitsu-ng-gov .mat-step-header .mat-step-icon-selected,
.toitsu-ng-gov .mat-step-header .mat-step-icon-state-done,
.toitsu-ng-gov .mat-step-header .mat-step-icon-state-edit {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-step-header.mat-accent .mat-step-icon-selected,
.toitsu-ng-gov .mat-step-header.mat-accent .mat-step-icon-state-done,
.toitsu-ng-gov .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-step-header.mat-warn .mat-step-icon-selected,
.toitsu-ng-gov .mat-step-header.mat-warn .mat-step-icon-state-done,
.toitsu-ng-gov .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ca2e2e;
}
.toitsu-ng-gov .mat-stepper-horizontal, .toitsu-ng-gov .mat-stepper-vertical {
  background-color: white;
}
.toitsu-ng-gov .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-horizontal-stepper-header::before,
.toitsu-ng-gov .mat-horizontal-stepper-header::after,
.toitsu-ng-gov .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-sort-header-arrow {
  color: #757575;
}
.toitsu-ng-gov .mat-tab-nav-bar,
.toitsu-ng-gov .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-tab-group-inverted-header .mat-tab-nav-bar,
.toitsu-ng-gov .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.toitsu-ng-gov .mat-tab-label, .toitsu-ng-gov .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.toitsu-ng-gov .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.toitsu-ng-gov .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(25, 70, 142, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-primary .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #003476;
}
.toitsu-ng-gov .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-gov .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 175, 239, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-accent .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 101, 71, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-warn .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(25, 70, 142, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header, .toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #003476;
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 175, 239, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header, .toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #046ec5;
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 101, 71, 0.3);
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header, .toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ca2e2e;
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-toolbar.mat-primary {
  background: #003476;
  color: white;
}
.toitsu-ng-gov .mat-toolbar.mat-accent {
  background: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-toolbar.mat-warn {
  background: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .mat-toolbar .mat-form-field-underline,
.toitsu-ng-gov .mat-toolbar .mat-form-field-ripple,
.toitsu-ng-gov .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.toitsu-ng-gov .mat-toolbar .mat-form-field-label,
.toitsu-ng-gov .mat-toolbar .mat-focused .mat-form-field-label,
.toitsu-ng-gov .mat-toolbar .mat-select-value,
.toitsu-ng-gov .mat-toolbar .mat-select-arrow,
.toitsu-ng-gov .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.toitsu-ng-gov .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.toitsu-ng-gov .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.toitsu-ng-gov .mat-tree {
  background: white;
}
.toitsu-ng-gov .mat-tree-node,
.toitsu-ng-gov .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .mat-simple-snackbar-action {
  color: #046ec5;
}
.toitsu-ng-gov .header {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .header-seperator {
  background-color: #046ec5;
}
.toitsu-ng-gov toitsu-ng-material-header-user .dropdown {
  color: rgba(0, 0, 0, 0.87);
  background: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov toitsu-ng-material-header-user .dropdown .list-item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.toitsu-ng-gov toitsu-ng-material-header-user .dropdown .list-item-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .footer-divider {
  background-color: #046ec5;
}
.toitsu-ng-gov .footer {
  background-color: #eeeeee;
  color: #616161;
}
.toitsu-ng-gov .footer .mat-icon-button.active {
  color: #003476;
}
.toitsu-ng-gov .sidenav {
  background-color: whitesmoke;
}
.toitsu-ng-gov .sidenav .sidenav-header {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .sidenav-item.active {
  background-color: #003476;
  color: white !important;
}
.toitsu-ng-gov .sidenav-item:hover {
  background-color: #003476;
  color: white !important;
}
.toitsu-ng-gov .sidenav-header {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov .banner {
  display: block;
  padding: 1.5em;
  background-color: rgba(97, 97, 97, 0.2);
  border-left: 5px solid #616161;
  color: #616161;
}
.toitsu-ng-gov .banner.success {
  background-color: rgba(0, 158, 63, 0.2);
  border-left: 5px solid #009e3f;
  color: #009e3f;
}
.toitsu-ng-gov .banner.warn {
  background-color: rgba(202, 46, 46, 0.2);
  border-left: 5px solid #ca2e2e;
  color: #ca2e2e;
}
.toitsu-ng-gov .block-ui-wrapper {
  background-color: rgba(0, 0, 0, 0.32) !important;
}
.toitsu-ng-gov .block-ui-wrapper.active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.toitsu-ng-gov .block-ui-spinner,
.toitsu-ng-gov .block-ui-template {
  position: unset !important;
  top: unset !important;
  margin: unset !important;
  left: unset !important;
  right: unset !important;
  transform: unset !important;
}
.toitsu-ng-gov .mat-raised-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-gov .mat-raised-button mat-icon {
  border-radius: 50%;
  background-color: white;
  color: #003476;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-gov .mat-raised-button mat-icon:first-child {
  margin-right: 1rem;
}
.toitsu-ng-gov .mat-raised-button mat-icon:last-child {
  margin-left: 1rem;
}
.toitsu-ng-gov .mat-raised-button.mat-button-disabled mat-icon {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .mat-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-gov .mat-button mat-icon {
  margin: 0 10px;
  border-radius: 50%;
  background-color: #003476;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-gov .mat-fab {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toitsu-ng-gov .mat-fab .mat-button-wrapper {
  padding: unset !important;
}
.toitsu-ng-gov .mat-fab mat-icon {
  font-weight: bold;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.toitsu-ng-gov .mat-form-field * {
  font: 400 1rem/1.2 var(--font-family) !important;
}
.toitsu-ng-gov .orbit-spinner,
.toitsu-ng-gov .orbit-spinner * {
  box-sizing: border-box;
}
.toitsu-ng-gov .orbit-spinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  perspective: 800px;
}
.toitsu-ng-gov .orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.toitsu-ng-gov .orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 12000ms linear infinite;
  border-bottom: 3px solid #046ec5;
}
.toitsu-ng-gov .orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 12000ms linear infinite;
  border-right: 3px solid #046ec5;
}
.toitsu-ng-gov .orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 12000ms linear infinite;
  border-top: 3px solid #046ec5;
}
@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.toitsu-ng-gov .snackbar-primary {
  background-color: #003476 !important;
  color: white !important;
}
.toitsu-ng-gov .snackbar-primary .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-gov .snackbar-success {
  background-color: #046ec5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov .snackbar-success .mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov .snackbar-error {
  background-color: #ca2e2e !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov .snackbar-error .mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov .mat-checkbox-label {
  white-space: normal;
}
.toitsu-ng-gov .mat-select-panel .mat-option {
  line-height: unset;
  height: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: unset;
}
.toitsu-ng-gov .mat-select-panel .mat-option .mat-option-text {
  white-space: unset;
}
.toitsu-ng-gov .custom-search-fields .search-row mat-icon {
  color: rgba(0, 0, 0, 0.54);
}
.toitsu-ng-gov .operator-option {
  text-align: center !important;
}
.toitsu-ng-gov .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(4, 110, 197, 0.5);
}
.toitsu-ng-gov .disabled-calendar-date .mat-calendar-body-cell-content,
.toitsu-ng-gov .mat-calendar-body-disabled .mat-calendar-body-cell-content {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.38);
  background-color: unset;
}
.toitsu-ng-gov .disabled-calendar-date {
  pointer-events: none;
}
.toitsu-ng-gov .highlighted-calendar-date .mat-calendar-body-cell-content {
  color: #003476;
}
.toitsu-ng-gov .timeframe.available {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov .timeframe.available.selected {
  background-color: #003476;
}
.toitsu-ng-gov .timeframe.unavailable {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov .booking-row {
  color: rgba(0, 0, 0, 0.87) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .booking-row:first-child {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov .error-message {
  color: #ca2e2e;
}
.toitsu-ng-gov a:not(.mat-raised-button):not(.mat-button) {
  color: #046ec5;
  text-decoration: underline;
}
.toitsu-ng-gov a:not(.mat-raised-button):not(.mat-button):visited {
  color: #4c2c92;
}
.toitsu-ng-gov a:not(.mat-raised-button):not(.mat-button):hover {
  color: #003476;
}
.toitsu-ng-gov.dark {
  /**
  **  Custom snackbar styles
   */
}
.toitsu-ng-gov.dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-option {
  color: white;
}
.toitsu-ng-gov.dark .mat-option:hover:not(.mat-option-disabled), .toitsu-ng-gov.dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.toitsu-ng-gov.dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.toitsu-ng-gov.dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.toitsu-ng-gov.dark .mat-primary .mat-pseudo-checkbox-checked,
.toitsu-ng-gov.dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #003476;
}
.toitsu-ng-gov.dark .mat-pseudo-checkbox-checked,
.toitsu-ng-gov.dark .mat-pseudo-checkbox-indeterminate,
.toitsu-ng-gov.dark .mat-accent .mat-pseudo-checkbox-checked,
.toitsu-ng-gov.dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #046ec5;
}
.toitsu-ng-gov.dark .mat-warn .mat-pseudo-checkbox-checked,
.toitsu-ng-gov.dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.toitsu-ng-gov.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.toitsu-ng-gov.dark .mat-app-background, .toitsu-ng-gov.dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.toitsu-ng-gov.dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.toitsu-ng-gov.dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.toitsu-ng-gov.dark .mat-badge {
  position: relative;
}
.toitsu-ng-gov.dark .mat-badge.mat-badge {
  overflow: visible;
}
.toitsu-ng-gov.dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.toitsu-ng-gov.dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.toitsu-ng-gov.dark .ng-animate-disabled .mat-badge-content,
.toitsu-ng-gov.dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.toitsu-ng-gov.dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.toitsu-ng-gov.dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.toitsu-ng-gov.dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.toitsu-ng-gov.dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.toitsu-ng-gov.dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.toitsu-ng-gov.dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.toitsu-ng-gov.dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .toitsu-ng-gov.dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.toitsu-ng-gov.dark .mat-badge-content {
  color: white;
  background: #003476;
}
.cdk-high-contrast-active .toitsu-ng-gov.dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.toitsu-ng-gov.dark .mat-badge-accent .mat-badge-content {
  background: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-badge-warn .mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-button, .toitsu-ng-gov.dark .mat-icon-button, .toitsu-ng-gov.dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.toitsu-ng-gov.dark .mat-button.mat-primary, .toitsu-ng-gov.dark .mat-icon-button.mat-primary, .toitsu-ng-gov.dark .mat-stroked-button.mat-primary {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-button.mat-accent, .toitsu-ng-gov.dark .mat-icon-button.mat-accent, .toitsu-ng-gov.dark .mat-stroked-button.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-button.mat-warn, .toitsu-ng-gov.dark .mat-icon-button.mat-warn, .toitsu-ng-gov.dark .mat-stroked-button.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-icon-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-icon-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-icon-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-stroked-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-stroked-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-stroked-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .toitsu-ng-gov.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.toitsu-ng-gov.dark .mat-button .mat-ripple-element, .toitsu-ng-gov.dark .mat-icon-button .mat-ripple-element, .toitsu-ng-gov.dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.toitsu-ng-gov.dark .mat-button-focus-overlay {
  background: white;
}
.toitsu-ng-gov.dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-flat-button, .toitsu-ng-gov.dark .mat-raised-button, .toitsu-ng-gov.dark .mat-fab, .toitsu-ng-gov.dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.toitsu-ng-gov.dark .mat-flat-button.mat-primary, .toitsu-ng-gov.dark .mat-raised-button.mat-primary, .toitsu-ng-gov.dark .mat-fab.mat-primary, .toitsu-ng-gov.dark .mat-mini-fab.mat-primary {
  color: white;
}
.toitsu-ng-gov.dark .mat-flat-button.mat-accent, .toitsu-ng-gov.dark .mat-raised-button.mat-accent, .toitsu-ng-gov.dark .mat-fab.mat-accent, .toitsu-ng-gov.dark .mat-mini-fab.mat-accent {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-warn, .toitsu-ng-gov.dark .mat-raised-button.mat-warn, .toitsu-ng-gov.dark .mat-fab.mat-warn, .toitsu-ng-gov.dark .mat-mini-fab.mat-warn {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-primary, .toitsu-ng-gov.dark .mat-raised-button.mat-primary, .toitsu-ng-gov.dark .mat-fab.mat-primary, .toitsu-ng-gov.dark .mat-mini-fab.mat-primary {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-flat-button.mat-accent, .toitsu-ng-gov.dark .mat-raised-button.mat-accent, .toitsu-ng-gov.dark .mat-fab.mat-accent, .toitsu-ng-gov.dark .mat-mini-fab.mat-accent {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-flat-button.mat-warn, .toitsu-ng-gov.dark .mat-raised-button.mat-warn, .toitsu-ng-gov.dark .mat-fab.mat-warn, .toitsu-ng-gov.dark .mat-mini-fab.mat-warn {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-flat-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-fab.mat-button-disabled.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-primary.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-accent.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-warn.mat-button-disabled, .toitsu-ng-gov.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-primary .mat-ripple-element, .toitsu-ng-gov.dark .mat-raised-button.mat-primary .mat-ripple-element, .toitsu-ng-gov.dark .mat-fab.mat-primary .mat-ripple-element, .toitsu-ng-gov.dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-accent .mat-ripple-element, .toitsu-ng-gov.dark .mat-raised-button.mat-accent .mat-ripple-element, .toitsu-ng-gov.dark .mat-fab.mat-accent .mat-ripple-element, .toitsu-ng-gov.dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-flat-button.mat-warn .mat-ripple-element, .toitsu-ng-gov.dark .mat-raised-button.mat-warn .mat-ripple-element, .toitsu-ng-gov.dark .mat-fab.mat-warn .mat-ripple-element, .toitsu-ng-gov.dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-stroked-button:not([class*=mat-elevation-z]), .toitsu-ng-gov.dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-fab:not([class*=mat-elevation-z]), .toitsu-ng-gov.dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .toitsu-ng-gov.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .toitsu-ng-gov.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.toitsu-ng-gov.dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.toitsu-ng-gov.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.toitsu-ng-gov.dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.toitsu-ng-gov.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.toitsu-ng-gov.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.toitsu-ng-gov.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.toitsu-ng-gov.dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.toitsu-ng-gov.dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.toitsu-ng-gov.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.toitsu-ng-gov.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.toitsu-ng-gov.dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.toitsu-ng-gov.dark .mat-card {
  background: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-checkbox-checkmark {
  fill: #303030;
}
.toitsu-ng-gov.dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.toitsu-ng-gov.dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.toitsu-ng-gov.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .toitsu-ng-gov.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .toitsu-ng-gov.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .toitsu-ng-gov.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .toitsu-ng-gov.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.toitsu-ng-gov.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.toitsu-ng-gov.dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.toitsu-ng-gov.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #003476;
}
.toitsu-ng-gov.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.toitsu-ng-gov.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #046ec5;
}
.toitsu-ng-gov.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.toitsu-ng-gov.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-table {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-table thead, .toitsu-ng-gov.dark .mat-table tbody, .toitsu-ng-gov.dark .mat-table tfoot,
.toitsu-ng-gov.dark mat-header-row, .toitsu-ng-gov.dark mat-row, .toitsu-ng-gov.dark mat-footer-row,
.toitsu-ng-gov.dark [mat-header-row], .toitsu-ng-gov.dark [mat-row], .toitsu-ng-gov.dark [mat-footer-row],
.toitsu-ng-gov.dark .mat-table-sticky {
  background: inherit;
}
.toitsu-ng-gov.dark mat-row, .toitsu-ng-gov.dark mat-header-row, .toitsu-ng-gov.dark mat-footer-row,
.toitsu-ng-gov.dark th.mat-header-cell, .toitsu-ng-gov.dark td.mat-cell, .toitsu-ng-gov.dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-cell, .toitsu-ng-gov.dark .mat-footer-cell {
  color: white;
}
.toitsu-ng-gov.dark .mat-calendar-arrow {
  fill: white;
}
.toitsu-ng-gov.dark .mat-datepicker-toggle,
.toitsu-ng-gov.dark .mat-datepicker-content .mat-calendar-next-button,
.toitsu-ng-gov.dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.toitsu-ng-gov.dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-calendar-table-header,
.toitsu-ng-gov.dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-calendar-body-cell-content,
.toitsu-ng-gov.dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.toitsu-ng-gov.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.toitsu-ng-gov.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-calendar-body-in-range::before {
  background: rgba(0, 52, 118, 0.2);
}
.toitsu-ng-gov.dark .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov.dark .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 52, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 52, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov.dark .mat-calendar-body-selected {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov.dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 52, 118, 0.4);
}
.toitsu-ng-gov.dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.toitsu-ng-gov.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 52, 118, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 52, 118, 0.3);
  }
}
.toitsu-ng-gov.dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(4, 110, 197, 0.2);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(4, 110, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(4, 110, 197, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(4, 110, 197, 0.4);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(4, 110, 197, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(4, 110, 197, 0.3);
  }
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(202, 46, 46, 0.2);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(202, 46, 46, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(202, 46, 46, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(202, 46, 46, 0.4);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(202, 46, 46, 0.3);
}
@media (hover: hover) {
  .toitsu-ng-gov.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(202, 46, 46, 0.3);
  }
}
.toitsu-ng-gov.dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-datepicker-toggle-active {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-datepicker-toggle-active.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-datepicker-toggle-active.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .toitsu-ng-gov.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .toitsu-ng-gov.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .toitsu-ng-gov.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.toitsu-ng-gov.dark .mat-expansion-panel-header-title {
  color: white;
}
.toitsu-ng-gov.dark .mat-expansion-panel-header-description,
.toitsu-ng-gov.dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.toitsu-ng-gov.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.toitsu-ng-gov.dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-focused .mat-form-field-required-marker {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field-ripple {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.toitsu-ng-gov.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.toitsu-ng-gov.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-error {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.toitsu-ng-gov.dark .mat-icon.mat-primary {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-icon.mat-accent {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-icon.mat-warn {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-input-element:disabled,
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-input-element {
  caret-color: #003476;
}
.toitsu-ng-gov.dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.toitsu-ng-gov.dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.toitsu-ng-gov.dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field.mat-warn .mat-input-element,
.toitsu-ng-gov.dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-list-base .mat-list-item {
  color: white;
}
.toitsu-ng-gov.dark .mat-list-base .mat-list-option {
  color: white;
}
.toitsu-ng-gov.dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-list-option:hover, .toitsu-ng-gov.dark .mat-list-option:focus,
.toitsu-ng-gov.dark .mat-nav-list .mat-list-item:hover,
.toitsu-ng-gov.dark .mat-nav-list .mat-list-item:focus,
.toitsu-ng-gov.dark .mat-action-list .mat-list-item:hover,
.toitsu-ng-gov.dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .mat-list-single-selected-option, .toitsu-ng-gov.dark .mat-list-single-selected-option:hover, .toitsu-ng-gov.dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-menu-panel {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-menu-item {
  background: transparent;
  color: white;
}
.toitsu-ng-gov.dark .mat-menu-item[disabled],
.toitsu-ng-gov.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.toitsu-ng-gov.dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-menu-item .mat-icon-no-color,
.toitsu-ng-gov.dark .mat-menu-submenu-icon {
  color: white;
}
.toitsu-ng-gov.dark .mat-menu-item:hover:not([disabled]),
.toitsu-ng-gov.dark .mat-menu-item.cdk-program-focused:not([disabled]),
.toitsu-ng-gov.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.toitsu-ng-gov.dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .mat-paginator {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-paginator,
.toitsu-ng-gov.dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-paginator-decrement,
.toitsu-ng-gov.dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.toitsu-ng-gov.dark .mat-paginator-first,
.toitsu-ng-gov.dark .mat-paginator-last {
  border-top: 2px solid white;
}
.toitsu-ng-gov.dark .mat-icon-button[disabled] .mat-paginator-decrement,
.toitsu-ng-gov.dark .mat-icon-button[disabled] .mat-paginator-increment,
.toitsu-ng-gov.dark .mat-icon-button[disabled] .mat-paginator-first,
.toitsu-ng-gov.dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-progress-bar-background {
  fill: #243142;
}
.toitsu-ng-gov.dark .mat-progress-bar-buffer {
  background-color: #243142;
}
.toitsu-ng-gov.dark .mat-progress-bar-fill::after {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #254055;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #254055;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #573030;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #573030;
}
.toitsu-ng-gov.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-progress-spinner circle, .toitsu-ng-gov.dark .mat-spinner circle {
  stroke: #003476;
}
.toitsu-ng-gov.dark .mat-progress-spinner.mat-accent circle, .toitsu-ng-gov.dark .mat-spinner.mat-accent circle {
  stroke: #046ec5;
}
.toitsu-ng-gov.dark .mat-progress-spinner.mat-warn circle, .toitsu-ng-gov.dark .mat-spinner.mat-warn circle {
  stroke: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003476;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.toitsu-ng-gov.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.toitsu-ng-gov.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.toitsu-ng-gov.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .toitsu-ng-gov.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .toitsu-ng-gov.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.toitsu-ng-gov.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.toitsu-ng-gov.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-select-value {
  color: white;
}
.toitsu-ng-gov.dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-select-panel {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #003476;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #046ec5;
}
.toitsu-ng-gov.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.toitsu-ng-gov.dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.toitsu-ng-gov.dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.toitsu-ng-gov.dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.toitsu-ng-gov.dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.toitsu-ng-gov.dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(4, 110, 197, 0.54);
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 52, 118, 0.54);
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(202, 46, 46, 0.54);
}
.toitsu-ng-gov.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.toitsu-ng-gov.dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-primary .mat-slider-track-fill,
.toitsu-ng-gov.dark .mat-slider.mat-primary .mat-slider-thumb,
.toitsu-ng-gov.dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.toitsu-ng-gov.dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 52, 118, 0.2);
}
.toitsu-ng-gov.dark .mat-slider.mat-accent .mat-slider-track-fill,
.toitsu-ng-gov.dark .mat-slider.mat-accent .mat-slider-thumb,
.toitsu-ng-gov.dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(4, 110, 197, 0.2);
}
.toitsu-ng-gov.dark .mat-slider.mat-warn .mat-slider-track-fill,
.toitsu-ng-gov.dark .mat-slider.mat-warn .mat-slider-thumb,
.toitsu-ng-gov.dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(202, 46, 46, 0.2);
}
.toitsu-ng-gov.dark .mat-slider:hover .mat-slider-track-background,
.toitsu-ng-gov.dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.toitsu-ng-gov.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.toitsu-ng-gov.dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .toitsu-ng-gov.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .toitsu-ng-gov.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.toitsu-ng-gov.dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-gov.dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.toitsu-ng-gov.dark .mat-step-header.cdk-keyboard-focused, .toitsu-ng-gov.dark .mat-step-header.cdk-program-focused, .toitsu-ng-gov.dark .mat-step-header:hover:not([aria-disabled]), .toitsu-ng-gov.dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .toitsu-ng-gov.dark .mat-step-header:hover {
    background: none;
  }
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-label,
.toitsu-ng-gov.dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-icon-selected,
.toitsu-ng-gov.dark .mat-step-header .mat-step-icon-state-done,
.toitsu-ng-gov.dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov.dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-step-header.mat-accent .mat-step-icon-selected,
.toitsu-ng-gov.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.toitsu-ng-gov.dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-step-header.mat-warn .mat-step-icon-selected,
.toitsu-ng-gov.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.toitsu-ng-gov.dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.toitsu-ng-gov.dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-stepper-horizontal, .toitsu-ng-gov.dark .mat-stepper-vertical {
  background-color: #424242;
}
.toitsu-ng-gov.dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-horizontal-stepper-header::before,
.toitsu-ng-gov.dark .mat-horizontal-stepper-header::after,
.toitsu-ng-gov.dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.toitsu-ng-gov.dark .mat-tab-nav-bar,
.toitsu-ng-gov.dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.toitsu-ng-gov.dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.toitsu-ng-gov.dark .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-link {
  color: white;
}
.toitsu-ng-gov.dark .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.toitsu-ng-gov.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.toitsu-ng-gov.dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(25, 70, 142, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-primary .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 175, 239, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-accent .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 101, 71, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-warn .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(25, 70, 142, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #003476;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 175, 239, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(251, 101, 71, 0.3);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ca2e2e;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.toitsu-ng-gov.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.toitsu-ng-gov.dark .mat-toolbar {
  background: #212121;
  color: white;
}
.toitsu-ng-gov.dark .mat-toolbar.mat-primary {
  background: #003476;
  color: white;
}
.toitsu-ng-gov.dark .mat-toolbar.mat-accent {
  background: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-toolbar.mat-warn {
  background: #ca2e2e;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .mat-toolbar .mat-form-field-underline,
.toitsu-ng-gov.dark .mat-toolbar .mat-form-field-ripple,
.toitsu-ng-gov.dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.toitsu-ng-gov.dark .mat-toolbar .mat-form-field-label,
.toitsu-ng-gov.dark .mat-toolbar .mat-focused .mat-form-field-label,
.toitsu-ng-gov.dark .mat-toolbar .mat-select-value,
.toitsu-ng-gov.dark .mat-toolbar .mat-select-arrow,
.toitsu-ng-gov.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.toitsu-ng-gov.dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.toitsu-ng-gov.dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.toitsu-ng-gov.dark .mat-tree {
  background: #424242;
}
.toitsu-ng-gov.dark .mat-tree-node,
.toitsu-ng-gov.dark .mat-nested-tree-node {
  color: white;
}
.toitsu-ng-gov.dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark .mat-simple-snackbar-action {
  color: inherit;
}
.toitsu-ng-gov.dark .header {
  background-color: #003476;
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .header-seperator {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark toitsu-ng-material-header-user .dropdown {
  color: white;
  background: #424242;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.toitsu-ng-gov.dark toitsu-ng-material-header-user .dropdown .list-item:hover {
  background: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark toitsu-ng-material-header-user .dropdown .list-item-icon {
  color: white;
}
.toitsu-ng-gov.dark .footer-divider {
  background-color: #046ec5;
}
.toitsu-ng-gov.dark .footer {
  background-color: #eeeeee;
  color: #616161;
  background-color: #383838;
}
.toitsu-ng-gov.dark .footer .mat-icon-button.active {
  color: #003476;
}
.toitsu-ng-gov.dark .sidenav {
  background-color: #2b2b2b;
}
.toitsu-ng-gov.dark .sidenav .sidenav-header {
  background-color: #003476;
  color: white;
  background-color: rgba(255, 255, 255, 0.04);
}
.toitsu-ng-gov.dark .sidenav-item.active {
  background-color: #003476;
  color: white !important;
}
.toitsu-ng-gov.dark .sidenav-item:hover {
  background-color: #003476;
  color: white !important;
}
.toitsu-ng-gov.dark .sidenav-header {
  background-color: #003476;
  color: white;
}
.toitsu-ng-gov.dark .banner {
  display: block;
  padding: 1.5em;
  background-color: rgba(97, 97, 97, 0.2);
  border-left: 5px solid #616161;
  color: #616161;
}
.toitsu-ng-gov.dark .banner.success {
  background-color: rgba(0, 158, 63, 0.2);
  border-left: 5px solid #009e3f;
  color: #009e3f;
}
.toitsu-ng-gov.dark .banner.warn {
  background-color: rgba(202, 46, 46, 0.2);
  border-left: 5px solid #ca2e2e;
  color: #ca2e2e;
}
.toitsu-ng-gov.dark .block-ui-wrapper {
  background-color: rgba(0, 0, 0, 0.32) !important;
}
.toitsu-ng-gov.dark .block-ui-wrapper.active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.toitsu-ng-gov.dark .block-ui-spinner,
.toitsu-ng-gov.dark .block-ui-template {
  position: unset !important;
  top: unset !important;
  margin: unset !important;
  left: unset !important;
  right: unset !important;
  transform: unset !important;
}
.toitsu-ng-gov.dark .mat-raised-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-gov.dark .mat-raised-button mat-icon {
  border-radius: 50%;
  background-color: white;
  color: #003476;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-gov.dark .mat-raised-button mat-icon:first-child {
  margin-right: 1rem;
}
.toitsu-ng-gov.dark .mat-raised-button mat-icon:last-child {
  margin-left: 1rem;
}
.toitsu-ng-gov.dark .mat-raised-button.mat-button-disabled mat-icon {
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .mat-button {
  height: 62px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: fit-content;
}
.toitsu-ng-gov.dark .mat-button mat-icon {
  margin: 0 10px;
  border-radius: 50%;
  background-color: #003476;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 30px;
}
.toitsu-ng-gov.dark .mat-fab {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toitsu-ng-gov.dark .mat-fab .mat-button-wrapper {
  padding: unset !important;
}
.toitsu-ng-gov.dark .mat-fab mat-icon {
  font-weight: bold;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.toitsu-ng-gov.dark .mat-form-field * {
  font: 400 1rem/1.2 var(--font-family) !important;
}
.toitsu-ng-gov.dark .orbit-spinner,
.toitsu-ng-gov.dark .orbit-spinner * {
  box-sizing: border-box;
}
.toitsu-ng-gov.dark .orbit-spinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  perspective: 800px;
}
.toitsu-ng-gov.dark .orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.toitsu-ng-gov.dark .orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 12000ms linear infinite;
  border-bottom: 3px solid #046ec5;
}
.toitsu-ng-gov.dark .orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 12000ms linear infinite;
  border-right: 3px solid #046ec5;
}
.toitsu-ng-gov.dark .orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 12000ms linear infinite;
  border-top: 3px solid #046ec5;
}
@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.toitsu-ng-gov.dark .snackbar-primary {
  background-color: #003476 !important;
  color: white !important;
}
.toitsu-ng-gov.dark .snackbar-primary .mat-simple-snackbar-action {
  color: white !important;
}
.toitsu-ng-gov.dark .snackbar-success {
  background-color: #046ec5 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov.dark .snackbar-success .mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov.dark .snackbar-error {
  background-color: #ca2e2e !important;
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov.dark .snackbar-error .mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.87) !important;
}
.toitsu-ng-gov.dark .mat-checkbox-label {
  white-space: normal;
}
.toitsu-ng-gov.dark .mat-select-panel .mat-option {
  line-height: unset;
  height: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: unset;
}
.toitsu-ng-gov.dark .mat-select-panel .mat-option .mat-option-text {
  white-space: unset;
}
.toitsu-ng-gov.dark .custom-search-fields .search-row mat-icon {
  color: white;
}
.toitsu-ng-gov.dark .operator-option {
  text-align: center !important;
}
.toitsu-ng-gov.dark .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(4, 110, 197, 0.5);
}
.toitsu-ng-gov.dark .disabled-calendar-date .mat-calendar-body-cell-content,
.toitsu-ng-gov.dark .mat-calendar-body-disabled .mat-calendar-body-cell-content {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5);
  background-color: unset;
}
.toitsu-ng-gov.dark .disabled-calendar-date {
  pointer-events: none;
}
.toitsu-ng-gov.dark .highlighted-calendar-date .mat-calendar-body-cell-content {
  color: #003476;
}
.toitsu-ng-gov.dark .timeframe.available {
  background-color: #046ec5;
  color: rgba(255, 255, 255, 0.87);
}
.toitsu-ng-gov.dark .timeframe.available.selected {
  background-color: #003476;
}
.toitsu-ng-gov.dark .timeframe.unavailable {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.toitsu-ng-gov.dark .booking-row {
  color: white !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .booking-row:first-child {
  border-top: 2px solid rgba(255, 255, 255, 0.12);
}
.toitsu-ng-gov.dark .error-message {
  color: #ca2e2e;
}
.toitsu-ng-gov.dark a:not(.mat-raised-button):not(.mat-button) {
  color: #046ec5;
  text-decoration: underline;
}
.toitsu-ng-gov.dark a:not(.mat-raised-button):not(.mat-button):visited {
  color: #4c2c92;
}
.toitsu-ng-gov.dark a:not(.mat-raised-button):not(.mat-button):hover {
  color: #003476;
}

.toitsu-ng-material-collapsible-sidenav {
  overflow: visible !important;
  border-right: none !important;
  background-color: transparent !important;
}
.toitsu-ng-material-collapsible-sidenav .mat-drawer-inner-container {
  overflow: visible !important;
}

:root {
  --font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-weight-medium: 500;
  --font-caption: 400 0.75rem / 1.7 var(--font-family);
  --font-body-1: 400 1.1875rem / 1.5 var(--font-family);
  --font-body-2: 500 1.1875rem / 1.5 var(--font-family);
  --font-subheading-1: bold 1.1875rem / 1.5 var(--font-family);
  --font-subheading-2: 500 1.5rem / 1.5 var(--font-family);
  --font-headline: bold 2.5rem / 65px var(--font-family);
  --font-title: bold 1.7rem / 1.2 var(--font-family);
  --font-display-1: 400 34px / 40px var(--font-family);
  --font-display-2: 400 45px / 48px var(--font-family);
  --font-display-3: 400 56px / 56px var(--font-family);
  --font-display-4: 300 112px / 112px var(--font-family);
}

.app-page {
  display: block;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
@media screen and (max-width: 599.9px) {
  .app-page {
    padding: 12px;
  }
}
@media screen and (min-width: 600px) and (max-width: 959.9px) {
  .app-page {
    padding: 17px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1279.9px) {
  .app-page {
    padding: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .app-page {
    padding: 26px;
  }
}
.app-page.fixed-heigth {
  height: calc(100vh - 128px - 120px);
}

.app-page-container {
  display: block !important;
  margin: auto;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  max-width: 1140px;
}

p {
  margin-bottom: 2em;
}

ul li {
  line-height: 32px;
}

ul.no-style {
  padding-left: 0;
}
ul.no-style li {
  list-style: none;
}

ul.links {
  padding-left: 0;
}
ul.links li {
  list-style: none;
}
ul.links li:before {
  content: "\e5cc";
  font-family: "Material Icons";
  font-weight: 600;
  display: inline-block;
  position: relative;
  top: 3px;
}

a {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.bg-article {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 35px;
}
.bg-article .bg-return {
  padding: 8px;
}
.bg-article .bg-main-section {
  flex-grow: 0;
  flex-basis: 50%;
  max-width: 50%;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.bg-article .bg-side-section {
  flex-grow: 0;
  flex-basis: 33.33%;
  max-width: 33.33%;
  padding: 8px;
  box-sizing: border-box;
  margin-left: auto;
}
@media screen and (max-width: 959.9px) {
  .bg-article .bg-main-section,
.bg-article .bg-side-section {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.search-highlight {
  font-weight: 600 !important;
}

.asterisk {
  display: inline-block;
}
.asterisk::after {
  content: "*";
  color: red;
  font: var(--font-body-2);
}