@import "../../_global/_styles/mixins";

.sidenav {
    background-color: darken($background-color, 2);

    .sidenav-header {
        background-color: $color-primary;
        color: $color-primary-contrast;

        @include dark-theme($is-dark-theme) {
            background-color: $background-hover;
            color: $foreground-color;
        }
    }
}

.sidenav-item {
    &.active {
        background-color: $color-primary;
        color: $color-primary-contrast !important;
    }

    &:hover {
        background-color: $color-primary;
        color: $color-primary-contrast !important;
    }
}
