@import "../../../_styles/mixins";

.app-page-container {
    display: block !important;
    margin: auto;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;

    max-width: 1140px;

    // @include media-sm {
    //     max-width: 600px;
    // }

    // @include media-md {
    //     max-width: 960px;
    // }

    // @include media-lg {
    //     max-width: 1140px;
    // }

    // @include media-xl {
    //     max-width: 1280px;
    // }
}
