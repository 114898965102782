// custom app styles
@import "../app/_global/_styles/mixins";

///////////////////////////////////////////////
//                 TYPOGRAPHY                //
/////////////////////////////////////////////*/

p {
    margin-bottom: 2em;
}

ul {
    li {
        line-height: 32px;
    }
}

ul.no-style {
    padding-left: 0;

    li {
        list-style: none;
    }
}

ul.links {
    padding-left: 0;

    li {
        list-style: none;

        &:before {
            // content: "";
            // display: inline-block;
            // height: 8px;
            // width: 8px;
            // background-image: url(https://www.gov.gr/_nuxt/img/bullet-arrow.9f99384.svg);
            // background-size: contain;
            // background-repeat: no-repeat;

            content: "\e5cc";
            font-family: "Material Icons";
            font-weight: 600;
            display: inline-block;
            position: relative;
            top: 3px;
        }
    }
}

a {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

////////////  End of TYPOGRAPHY  //////////////

.bg-article {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    margin-top: 35px;
    margin-bottom: 35px;

    .bg-return {
        padding: 8px;
    }

    .bg-main-section {
        flex-grow: 0;
        // flex-basis: 66.66%;
        // max-width: 66.66%;
        flex-basis: 50%;
        max-width: 50%;
        padding: 8px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
    }

    .bg-side-section {
        flex-grow: 0;
        flex-basis: 33.33%;
        max-width: 33.33%;
        padding: 8px;
        box-sizing: border-box;
        margin-left: auto;
    }

    @include media-lt-md {
        .bg-main-section,
        .bg-side-section {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

.search-highlight {
    font-weight: 600 !important;
}

.asterisk {
    display: inline-block;

    &::after {
        content: "*";
        color: red;
        font: var(--font-body-2);
    }
}
