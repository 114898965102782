@mixin dark-theme($dark: false) {
    @if $dark {
        @content;
    }
}

$media-xs: "screen and (max-width: 599.9px)";
$media-sm: "screen and (min-width: 600px) and (max-width: 959.9px)";
$media-md: "screen and (min-width: 960px) and (max-width: 1279.9px)";
$media-lg: "screen and (min-width: 1280px)";

$media-gt-xs: "screen and (min-width: 600px)";
$media-gt-sm: "screen and (min-width: 960px)";
$media-gt-md: "screen and (min-width: 1280px)";

$media-lt-md: "screen and (max-width: 959.9px)";
$media-lt-lg: "screen and (max-width: 1279.9px)";

@mixin media-xs {
    @media #{$media-xs} {
        @content;
    }
}

@mixin media-sm {
    @media #{$media-sm} {
        @content;
    }
}

@mixin media-md {
    @media #{$media-md} {
        @content;
    }
}

@mixin media-lg {
    @media #{$media-lg} {
        @content;
    }
}

@mixin media-gt-xs {
    @media #{$media-gt-xs} {
        @content;
    }
}

@mixin media-gt-sm {
    @media #{$media-gt-sm} {
        @content;
    }
}

@mixin media-gt-md {
    @media #{$media-gt-md} {
        @content;
    }
}

@mixin media-lt-md {
    @media #{$media-lt-md} {
        @content;
    }
}

@mixin media-lt-lg {
    @media #{$media-lt-lg} {
        @content;
    }
}
