@mixin banner-color($color) {
    background-color: rgba($color, 0.2);
    border-left: 5px solid $color;
    color: $color;
}

.banner {
    display: block;
    padding: 1.5em;

    @include banner-color(#616161);

    &.success {
        @include banner-color(#009e3f);
    }

    &.warn {
        @include banner-color($color-warn);
    }
}
