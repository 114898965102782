.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: rgba($color-accent, 0.5);
}

.disabled-calendar-date .mat-calendar-body-cell-content,
.mat-calendar-body-disabled .mat-calendar-body-cell-content {
    pointer-events: none;
    color: $disabled-text-color;
    background-color: unset;
}

.disabled-calendar-date {
    pointer-events: none;
}

.highlighted-calendar-date {
    .mat-calendar-body-cell-content {
        color: $color-primary;
    }
}

.timeframe {
    &.available {
        background-color: $color-accent;
        // background-color: rgba($color-accent, .7);
        color: $color-accent-contrast;

        &.selected {
            background-color: $color-primary;
        }
    }

    &.unavailable {
        // background-color: $color-warn;
        // background-color: rgba($color-warn, .5);
        // color: $color-warn-contrast;
        background-color: rgba(0, 0, 0, 0.12);
        color: $disabled-text-color;
    }
}
