@import "../app/_layout/layout.theme.scss";
@import "./custom/custom.scss";

// import component theme files
@import "../app/_global/_components/custom-search-fields/custom-search-field.component.theme.scss";

@import "../app/main/booking/calendar/calendar.component.theme.scss";
@import "../app/main/user/user-bookings/user-bookings.component.theme.scss";
@import "../app/main/booking/_dialogs/simple-dialog/simple-dialog.component.theme.scss";

// global theming
a:not(.mat-raised-button):not(.mat-button) {
    color: $color-accent;
    text-decoration: underline;

    &:visited {
        color: #4c2c92;
    }

    &:hover {
        color: $color-primary;
    }
}
