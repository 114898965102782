// Block UI override styles
.block-ui-wrapper {
    background-color: rgba(0, 0, 0, 0.32) !important;
}

.block-ui-wrapper.active {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.block-ui-spinner,
.block-ui-template {
    position: unset !important;
    top: unset !important;
    margin: unset !important;
    left: unset !important;
    right: unset !important;
    transform: unset !important;
}
