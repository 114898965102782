@import "../../../_global/_styles/var";
@import "../../../_global/_styles/mixins";
@import "../../../_layout/layout_var.scss";

.app-page {
    display: block;
    height: 100%;
    overflow-y: auto;
    // padding: 10px;
    box-sizing: border-box;

    @include media-xs {
        padding: $padding-xs;
    }

    @include media-sm {
        padding: $padding-sm;
    }

    @include media-md {
        padding: $padding;
    }

    @include media-lg {
        padding: $padding-lg;
    }

    &.fixed-heigth {
        height: calc(100vh - $header-height - $footer-height);
    }
}
