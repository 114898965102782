.mat-raised-button {
    height: 62px;
    padding: 10px 20px;
    font-size: 1rem;

    display: flex;
    align-items: center;
    width: fit-content;

    mat-icon {
        // margin: 0 10px;
        border-radius: 50%;
        background-color: $color-primary-contrast;
        color: $color-primary;
        width: 30px;
        height: 30px;
        font-size: 30px;

        &:first-child {
            margin-right: 1rem;
        }

        &:last-child {
            margin-left: 1rem;
        }
    }

    &.mat-button-disabled {
        mat-icon {
            color: $disabled-text-color;
        }
    }
}

.mat-button {
    height: 62px;
    padding: 10px 20px;
    font-size: 1rem;

    display: flex;
    align-items: center;
    width: fit-content;

    mat-icon {
        margin: 0 10px;
        border-radius: 50%;
        background-color: $color-primary;
        color: $color-primary-contrast;
        width: 30px;
        height: 30px;
        font-size: 30px;
    }
}

.mat-fab {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-button-wrapper {
        padding: unset !important;
    }

    mat-icon {
        font-weight: bold;
        font-size: 30px;
        width: 30px;
        height: 30px;
    }
}
