.custom-search-fields {
    .search-row {
        mat-icon {
            color: $icon-color;
        }
    }
}

.operator-option {
    text-align: center !important;
}
