.mat-checkbox-label {
    white-space: normal;
}

// .mat-form-field-appearance-outline .mat-form-field-outline-thick {
//     color: $color-primary;
//     opacity: 1;
// }

// .mat-form-field *:not(.mat-select-placeholder) {
//     font-weight: 700 !important;
// }

// .mat-select-arrow {
//     border-left: 9px solid transparent;
//     border-right: 9px solid transparent;
//     border-top: 9px solid;
//     color: $color-primary;
// }

// .mat-select-placeholder {
//     color: $text-color;
// }

.mat-select-panel .mat-option {
    line-height: unset;
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;

    white-space: unset;

    .mat-option-text {
        white-space: unset;
    }
}
