// COLLAPSABLE SIDENAV
.toitsu-ng-material-collapsible-sidenav {
    overflow: visible !important;
    border-right: none !important;
    background-color: transparent !important;

    .mat-drawer-inner-container {
        overflow: visible !important;
    }
}
