@use "sass:list";
@use "@angular/material" as mat;

@import "@angular/material/theming";

$typograpgy-config: mat.define-typography-config(
    // $font-family: var(--font-family),
    /* $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    // $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    // $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    // $display-1: mat.define-typography-level(34px, 40px, 400),
    // $headline: mat.define-typography-level(24px, 32px, 400),
    // $title: mat.define-typography-level(18px, 26px, 500),
    // $subheading-2: mat.define-typography-level(16px, 28px, 400),
    // $subheading-1: mat.define-typography-level(15px, 24px, 400),
    // $body-2: mat.define-typography-level(14px, 20px, 500),
    // $body-1: mat.define-typography-level(14px, 18px, 400),
    // $caption: mat.define-typography-level(12px, 20px, 400),
    // $button: mat.define-typography-level(14px, 16px, 500),
    // $input: mat.define-typography-level(14px, 1.125, 400), */
        $font-family: var(--font-family),
    $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $display-1: mat.define-typography-level(34px, 40px, 400),
    $headline: mat.define-typography-level(2.5rem, 65px, bold, $letter-spacing: -0.035em),
    /* $title: mat.define-typography-level(2.25rem, 50px, bold, $letter-spacing: -0.035em), */ $title:
        mat.define-typography-level(1.7rem, 1.2, bold, $letter-spacing: -0.035em),
    $subheading-2: mat.define-typography-level(1.5rem, 1.5, 500, $letter-spacing: -0.035em),
    $subheading-1: mat.define-typography-level(1.1875rem, 1.5, bold, $letter-spacing: -0.035em),
    $body-2: mat.define-typography-level(1.1875rem, 1.5, 500),
    $body-1: mat.define-typography-level(1.1875rem, 1.5, 400),
    $caption: mat.define-typography-level(0.75rem, 1.7, 400),
    $button: mat.define-typography-level(1.1875rem, 26px, 700),
    $input: mat.define-typography-level(1.1875rem, 1.125, 400)
);

:root {
    --font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    // Typography
    --font-weight-medium: 500;
    --font-caption: #{mat.font-weight($typograpgy-config, caption)
        list.slash(mat.font-size($typograpgy-config, caption), mat.line-height($typograpgy-config, caption))
        mat.font-family($typograpgy-config, caption)};
    --font-body-1: #{mat.font-weight($typograpgy-config, body-1)
        list.slash(mat.font-size($typograpgy-config, body-1), mat.line-height($typograpgy-config, body-1))
        mat.font-family($typograpgy-config, body-1)};
    --font-body-2: #{mat.font-weight($typograpgy-config, body-2)
        list.slash(mat.font-size($typograpgy-config, body-2), mat.line-height($typograpgy-config, body-2))
        mat.font-family($typograpgy-config, body-2)};
    --font-subheading-1: #{mat.font-weight($typograpgy-config, subheading-1)
        list.slash(mat.font-size($typograpgy-config, subheading-1), mat.line-height($typograpgy-config, subheading-1))
        mat.font-family($typograpgy-config, subheading-1)};
    --font-subheading-2: #{mat.font-weight($typograpgy-config, subheading-2)
        list.slash(mat.font-size($typograpgy-config, subheading-2), mat.line-height($typograpgy-config, subheading-2))
        mat.font-family($typograpgy-config, subheading-2)};
    --font-headline: #{mat.font-weight($typograpgy-config, headline)
        list.slash(mat.font-size($typograpgy-config, headline), mat.line-height($typograpgy-config, headline))
        mat.font-family($typograpgy-config, headline)};
    --font-title: #{mat.font-weight($typograpgy-config, title)
        list.slash(mat.font-size($typograpgy-config, title), mat.line-height($typograpgy-config, title))
        mat.font-family($typograpgy-config, title)};
    --font-display-1: #{mat.font-weight($typograpgy-config, display-1)
        list.slash(mat.font-size($typograpgy-config, display-1), mat.line-height($typograpgy-config, display-1))
        mat.font-family($typograpgy-config, display-1)};
    --font-display-2: #{mat.font-weight($typograpgy-config, display-2)
        list.slash(mat.font-size($typograpgy-config, display-2), mat.line-height($typograpgy-config, display-2))
        mat.font-family($typograpgy-config, display-2)};
    --font-display-3: #{mat.font-weight($typograpgy-config, display-3)
        list.slash(mat.font-size($typograpgy-config, display-3), mat.line-height($typograpgy-config, display-3))
        mat.font-family($typograpgy-config, display-3)};
    --font-display-4: #{mat.font-weight($typograpgy-config, display-4)
        list.slash(mat.font-size($typograpgy-config, display-4), mat.line-height($typograpgy-config, display-4))
        mat.font-family($typograpgy-config, display-4)};
}

$font-display-4: var(--font-display-4);
$font-display-3: var(--font-display-3);
$font-display-2: var(--font-display-2);
$font-display-1: var(--font-display-1);
$font-headline: var(--font-headline);
$font-title: var(--font-title);
$font-subheading-2: var(--font-subheading-2);
$font-subheading-1: var(--font-subheading-1);
$font-body-2: var(--font-body-2);
$font-body-1: var(--font-body-1);
$font-caption: var(--font-caption);
