@import "../_theme/_config/theme-typography";

$padding-0: 0;
$padding-xs: 12px;
$padding-sm: 17px;
$padding: 20px;
$padding-lg: 26px;
$padding-xl: 30px;

$border-radius: 8px;
