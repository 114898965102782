/**
**  Custom snackbar styles
 */

.snackbar-primary {
    background-color: $color-primary !important;
    color: $color-primary-contrast !important;

    .mat-simple-snackbar-action {
        color: $color-primary-contrast !important;
    }
}

.snackbar-success {
    background-color: $color-accent !important;
    color: $color-accent-contrast !important;

    .mat-simple-snackbar-action {
        color: $color-accent-contrast !important;
    }
}

.snackbar-error {
    background-color: $color-warn !important;
    color: $color-warn-contrast !important;

    .mat-simple-snackbar-action {
        color: $color-warn-contrast !important;
    }
}
