@import "@angular/material/theming";
@import "./_config/theme-typography";

@include mat-core($typograpgy-config);

$theme-primary-hue: 500 !default;
$theme-primary-hue-lighter: 400 !default;
$theme-primary-hue-darker: 700 !default;
$theme-accent-hue: 500 !default;
$theme-accent-hue-lighter: 400 !default;
$theme-accent-hue-darker: 700 !default;
$theme-warn-hue: 500 !default;
$theme-warn-hue-lighter: 400 !default;
$theme-warn-hue-darker: 700 !default;

.toitsu-ng-default {
    $default-theme-primary: mat-palette($mat-blue);
    $default-theme-accent: mat-palette($mat-green, 600);
    $default-theme-warn: mat-palette($mat-red);

    $theme: mat-light-theme($default-theme-primary, $default-theme-accent, $default-theme-warn);
    @include angular-material-theme($theme);
    // @import "./_config/config.scss";
    @import "./_config/theme-colors";
    @import "../../../styles/app-theming.scss";

    &.dark {
        $theme: mat-dark-theme($default-theme-primary, $default-theme-accent, $default-theme-warn);
        @include angular-material-theme($theme);
        // @import "./_config/config.scss";
        @import "./_config/theme-colors";
        @import "../../../styles/app-theming.scss";
    }
}

.toitsu-ng-gov {
    @import "./_palettes/gov";
    $gov-theme-primary: mat-palette($gov-primary, $theme-primary-hue, $theme-primary-hue-lighter, $theme-primary-hue-darker);
    $gov-theme-accent: mat-palette($gov-accent, $theme-accent-hue, $theme-accent-hue-lighter, $theme-accent-hue-darker);
    $gov-theme-warn: mat-palette($gov-warn, $theme-warn-hue, $theme-warn-hue-lighter, $theme-warn-hue-darker);

    $theme: mat-light-theme($gov-theme-primary, $gov-theme-accent, $gov-theme-warn);
    @include angular-material-theme($theme);
    // @import "./_config/config.scss";
    @import "./_config/theme-colors";
    @import "../../../styles/app-theming.scss";

    &.dark {
        $theme: mat-dark-theme($gov-theme-primary, $gov-theme-accent, $gov-theme-warn);
        @include angular-material-theme($theme);
        // @import "./_config/config.scss";
        @import "./_config/theme-colors";
        @import "../../../styles/app-theming.scss";
    }
}
