html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";

    $scrollbar-width: 10px !default;

    @media only screen and (min-width: 991px) {
        ::-webkit-scrollbar {
            width: $scrollbar-width;
            height: $scrollbar-width;
            background-color: transparent;
        }

        ::-webkit-scrollbar:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        ::-webkit-scrollbar-thumb {
            border: 2px solid transparent;
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
            border-radius: $scrollbar-width;
        }

        ::-webkit-scrollbar-thumb:active {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
            border-radius: $scrollbar-width;
        }

        &.dark {
            ::-webkit-scrollbar:hover {
                background-color: rgba(255, 255, 255, 0.12);
            }

            ::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.37);
            }

            ::-webkit-scrollbar-thumb:active {
                box-shadow: inset 0 0 0 12px rgba(255, 255, 255, 0.54);
            }
        }
    }
}
