@use "sass:map";
@use "@angular/material"as mat;

// extract theme palettes
$color-config: mat.get-color-config($theme);
$primary-palette: map.get($color-config, "primary");
$accent-palette: map.get($color-config, "accent");
$warn-palette: map.get($color-config, "warn");
$is-dark-theme: map.get($color-config, "is-dark");

// theme colors
$color-primary: mat.get-color-from-palette($primary-palette);
$color-accent: mat.get-color-from-palette($accent-palette);
$color-warn: mat.get-color-from-palette($warn-palette);

// theme colors contrast
$color-primary-contrast: mat.get-contrast-color-from-palette($primary-palette, $theme-primary-hue);
$color-accent-contrast: mat.get-contrast-color-from-palette($accent-palette, $theme-accent-hue);
$color-warn-contrast: mat.get-contrast-color-from-palette($warn-palette, $theme-warn-hue);

// theme colors ligher

// theme colors lighter contrast

// theme colors darker

// theme colors darker contrast

// theme background and foreground
$background-map: map.get($color-config, "background");
$foreground-map: map.get($color-config, "foreground");

$foreground-color: mat.get-color-from-palette($foreground-map, "foreground");
$background-color: mat.get-color-from-palette($background-map, "background");
$background-hover: mat.get-color-from-palette($background-map, "hover");
$background-disabled-color: mat.get-color-from-palette($background-map, "disabled");

// text colors
$text-color: mat.get-color-from-palette($foreground-map, "text");
$hint-text-color: mat.get-color-from-palette($foreground-map, "hint-text");
// $secondary-text-color: mat.get-color-from-palette($foreground-map, "secondary-text");
$disabled-text-color: mat.get-color-from-palette($foreground-map, "disabled-text");
$secondary-text-color: #616161;

// misc
$background-card: mat.get-color-from-palette($background-map, "card");
$backdrop-color: mat.get-color-from-palette($background-map, "backdrop");

$icon-color: mat.get-color-from-palette($foreground-map, "icon");
$divider-color: mat.get-color-from-palette($foreground-map, "divider");
